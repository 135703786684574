import React, { useEffect, useMemo, useState } from 'react'
import {
  FaListUl,
  FaThLarge,
  FaChevronDown,
  FaEllipsisH,
  FaUpload,
  FaVideoSlash,
  FaLink,
  FaTimes,
} from 'react-icons/fa'
import { useQuery } from '@apollo/client'

import { getMediaByScenarioIdQuery } from '../../../../apollo/query/media'
import useMediaUploader from '../../../../hooks/useMediaUploader'
import withApollo from '../../../../hooks/withApollo'
import Checkbox from '../../../../components/UI/Form/Checkbox'
import { default as S } from '../../../../utils/lang/en.json'
import Modal from '../../../../components/UI/Modal'
import Toast from '../../../../components/UI/Notifications/Toast'
import Filter from '../../../../components/UI/Menu/Filter'
import { getStoreQuery } from '../../../../apollo/query/store'
import { updateStore } from '../../../../utils/helpers/graphql'
import LoadingSpinner from '../../../../components/UI/LoadingSpinner'
import Table from '../../../../components/Table'
import {
  convertMilliSeconds,
  convertResolution,
  convertSize,
  convertMediaType,
  dateFormat,
  sortOnKey,
} from '../../../../utils/format'
import Grid from '../../../../components/Grid'
import Delay from '../../../../utils/helpers/Delay'
import MediaEmptyState from '../../../../components/Media/MediaEmptyState'
import FilterEmptyState from '../../../../components/UI/Menu/FilterEmptyState'
import UploadLabel from '../../../../components/Media/UploadLabel'
import TableColumnsDropdown from '../../../../components/UI/TableColumnsDropdown'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import usePreferences from '../../../../hooks/usePreferences'
import {
  filterColumnsHandler,
  sortHandler,
} from '../../../../components/tableFunctions'
import TableSearchBar from '../../../../components/UI/Table/TableSearchBar'
import MediaOptionsDropdown from '../../../../components/Media/MediaOptionsDropdown'
import DeleteMediumModal from '../../../../components/Media/Modal/DeleteMediumModal'
import CopyMediumModal from '../../../../components/Media/Modal/CopyMediumModal'
import Dropdown from '../../../../components/UI/Menu/Dropdown'
import MediaSceneTooltip from '../../../../components/Media/Tooltips/MediaSceneTooltip'
import MediumModal from '../../../../components/Media/Modal/MediumModal'
import { MediumProvider } from '../../../../components/Media/Modal/MediumContext'
import VideoTrimIcon from '../../../../utils/helpers/VideoTrimIcon'
import DataTooltip from '../../../../components/UI/DataTooltip'
import ReplaceMediumModal from '../../../../components/Media/Modal/ReplaceMediumModal'
import MediaUploadModal from './MediaUploadModal'
import useFoundation from '../../../../hooks/useFoundation'

const tableHeaders = [
  'name',
  'kind',
  'duration',
  'linkedToScenes',
  'size',
  'width',
  'createdAt',
]

const MediaTable = ({
  id = 'media',
  isLink,
  scenarioId,
  selectedId,
  onLinkClick = () => {},
  deleteCallback = () => {},
  openSceneCallback,
  selectedFilterOption = '',
  hideTypeFilter,
  reactFlow,
  legacyFlow,
}) => {
  const [preferences, setPreferences] = usePreferences('media')
  const [currentUser, currentClient] = useCurrentUser()

  const [mediumId, setMediumId] = useState(null)
  const [showEmptyState, setShowEmptyState] = useState(false)
  const [showEmptyScenario, setShowEmptyScenario] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedItems, setSelectedItems] = useState([])
  const [dismissToast, setDismissToast] = useState(false)
  const [filesUploading, setFilesUploading] = useState(false)
  const [uploadMediaItems, setUploadMediaItems] = useMediaUploader()
  const ref = useFoundation()
  const mediumModalId = `${id}-mediumModal`

  const { loading, data, startPolling, stopPolling, refetch } = useQuery(
    getMediaByScenarioIdQuery,
    {
      variables: {
        scenarioId: scenarioId,
      },
    }
  )

  const { data: store } = useQuery(getStoreQuery)

  window.onbeforeunload = () => {
    if (uploadMediaItems.length) {
      return ''
    }
    return null
  }
  const { mediaStore } = store
  const filterOption = selectedFilterOption || mediaStore.filterOption

  const getNameLabel = (item) => {
    if (item.state && item.state !== 'COMPLETED')
      return <UploadLabel item={item} />
    if (item.trimEnabled)
      return (
        <>
          <span className="mr-2">{item.name}</span>
          <span className="float-right">
            <DataTooltip title="Video is trimmed">
              <VideoTrimIcon />
            </DataTooltip>
          </span>
        </>
      )
    return null
  }

  const getUploadData = () => {
    return uploadMediaItems.map((item) => {
      return {
        id: item.id,
        loadingIcon: item.state === 'UPLOADING' && <FaUpload />,
        subtitle: <UploadLabel item={item} showFileName={false} />,
        name: {
          value: item.name,
          render: <UploadLabel item={item} />,
        },
      }
    })
  }

  const sortedData = useMemo(() => {
    if (!data?.media || !preferences) return []

    const { value, isAscending } = preferences.sortOn
    const stereoscopicFilter =
      typeof filterOption === 'object'
        ? null
        : filterOption.split(' ')[0].toLowerCase()
    const typeFilter =
      typeof filterOption === 'object'
        ? filterOption.map((o) => o.split(' ')[1])
        : filterOption.split(' ')[1]

    const scenarioList = sortOnKey([...data.media], value, isAscending).reduce(
      (array, item) => {
        array.push({
          id: item.id,
          imageUrl: item.previewUrl,
          stereoscopic: item.stereoscopic,
          name: {
            value: item.name,
            render: getNameLabel(item),
          },
          kind: {
            value: item.stereoscopic || item.kind,
            render: convertMediaType(item.stereoscopic, item.kind),
          },
          duration: convertMilliSeconds(item.duration) || '-',
          linkedToScenes: <MediaSceneTooltip scenes={item.linkedToScenes} />,
          size: convertSize(item.size),
          state: {
            value: item.state,
            render: item.state !== 'COMPLETED' && (
              <UploadLabel item={item} showFileName={false} />
            ),
          },
          width: convertResolution(item.width),
          createdAt: dateFormat(item.createdAt),
          subtitle: convertMediaType(item.stereoscopic, item.kind),
        })
        return array
      },
      []
    )

    // scenario is empty
    if (!scenarioList.length && !showEmptyScenario) setShowEmptyScenario(true)
    if (scenarioList.length && showEmptyScenario) setShowEmptyScenario(false)

    // filter searchQuery
    const filtered = scenarioList.filter((medium) => {
      return medium.name.value.toLowerCase().includes(searchQuery.toLowerCase())
    })

    // filter stereoscopic
    const final = filtered.reduce((a, item) => {
      if (
        (!stereoscopicFilter ||
          item.stereoscopic === (stereoscopicFilter === 'stereo')) &&
        (!typeFilter ||
          (typeof filterOption === 'object'
            ? typeFilter.includes(item.kind.value)
            : item.kind.value === typeFilter))
      ) {
        a.push(item)
      }
      return a
    }, [])

    if (!final.length && !showEmptyState) setShowEmptyState(true)
    if (final.length && showEmptyState) setShowEmptyState(false)

    if (uploadMediaItems) {
      const uploading = getUploadData()
      final.unshift(...uploading)
    }

    return final
  }, [
    data,
    selectedFilterOption,
    mediaStore,
    searchQuery,
    preferences,
    uploadMediaItems,
  ])

  useEffect(() => {
    refetch()
  }, [uploadMediaItems, scenarioId])

  useEffect(() => {
    // Start polling during uploading
    if (data) {
      const isUploading = data.media.some((item) =>
        ['QUEUED', 'PROGRESSING'].includes(item.state)
      )

      if (isUploading) startPolling(1000 * 5)
      if (!isUploading) {
        stopPolling()
        setFilesUploading(false)
      }
    }
  }, [data])

  useEffect(() => {
    if (filesUploading) {
      // file upload was finished..
      setTimeout(() => {
        startPolling(2 * 1000)
      }, 1000)
    }
  }, [filesUploading])

  useEffect(() => {
    return () => {
      document.querySelector(`#${mediumModalId}`)?.remove()
    }
  }, [])

  if (!store) return null

  // loading user & preferences
  if (!preferences || !currentUser || !currentClient) {
    return <LoadingSpinner text="Loading user..." />
  }

  // loading media items
  if (loading) {
    return (
      <Delay>
        <LoadingSpinner text="Fetching media items..." />
      </Delay>
    )
  }

  const uploadFilesHandler = (files, kind) => {
    setFilesUploading(true)

    const kindToMediumKind = (kind, file) => {
      const ext = file.name.split('.').pop()?.toLowerCase()
      const type = ext === 'mp4' ? 'VIDEO' : 'IMAGE'
      if (kind === 'flat') return `FLAT_${type}`
      return `${type}_${kind.toUpperCase()}`
    }

    setUploadMediaItems(
      files.map((file) => {
        file.sid = scenarioId
        file.kind = (kind && kindToMediumKind(kind, file)) || 'VIDEO_360'
        return file
      })
    )
  }

  const dismissToastHandler = () => {
    setDismissToast(true)
    setTimeout(() => {
      setDismissToast(false)
    }, 300)
  }

  const openHandler = (id) => {
    const ids = uploadMediaItems.map((i) => i.id)
    if (!ids.includes(id)) {
      setMediumId(id)
      $(`#${mediumModalId}`).foundation('open')
      $(`#${mediumModalId}`).on('closed.zf.reveal', () => {
        // stop playing:
        setMediumId(null)
      })
    }
  }
  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    if (!newSelection.length) dismissToastHandler()
    return setSelectedItems(newSelection)
  }

  const content = () => {
    const list = tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={
            !disabled
              ? (e) => {
                  e.preventDefault()
                  filterColumnsHandler(item, preferences, setPreferences)
                }
              : null
          }>
          <Checkbox
            style={{ marginBottom: '0px', cursor: 'default' }}
            disabled={disabled}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.media.headers[item]}
          />
        </li>
      )
    })
    return <>{list}</>
  }

  const tableSearchBar = () => {
    return (
      <>
        <span
          className="cell shrink large-auto mr-1 large-order-2"
          hidden={hideTypeFilter}>
          <Filter
            text="Type"
            icon={<FaChevronDown />}
            filterHandler={(filter) => {
              updateStore(mediaStore, {
                filterOption: filter === 'All' ? '' : filter,
              })
            }}
            selected={filterOption || 'All'}
            filterOptions={[
              'All',
              { render: 'Mono 360° video', value: 'MONO VIDEO_360' },
              { render: 'Stereo 360° video', value: 'STEREO VIDEO_360' },
              { render: 'Flat video', value: ' FLAT_VIDEO' },
              { render: 'Flat image', value: ' FLAT_IMAGE' },

              ...(currentClient.features.includes('VIDEO_180')
                ? [
                    { render: 'Mono 180° video', value: 'MONO VIDEO_180' },
                    {
                      render: 'Stereo 180° video',
                      value: 'Stereo 180° video VIDEO_180',
                    },
                  ]
                : []),

              ...(currentClient.features.includes('IMAGE_3D')
                ? [{ render: '360° image', value: ' IMAGE_360' }]
                : []),
            ]}
          />
        </span>
        <TableSearchBar
          searchQuery={searchQuery}
          setShowEmptyState={setShowEmptyState}
          showEmptyState={showEmptyState}
          setSearchQuery={setSearchQuery}
        />
      </>
    )
  }

  const optionsDropdownHandler = (mediaId) => {
    if (isLink) {
      const media = data.media.find((m) => m.id === mediaId)
      if (!['COMPLETED', 'PROGRESSING', 'QUEUED'].includes(media?.state))
        return null
      if (selectedId === mediaId)
        return (
          <label className="o-label--custom o-label--stable-lighter text-bold text-stable-dark">
            Linked
          </label>
        )
      return (
        <button
          className="o-button o-button--auto-width o-button--auto-height text-small text-bold"
          onClick={() => onLinkClick(mediaId)}
          style={{
            ...(preferences.showTable
              ? {}
              : {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateY(calc(-50% - 17.5px)) translateX(-50%)',
                }),
          }}>
          <FaLink className="mr-0-5" />
          Link
        </button>
      )
    }
    const ids = uploadMediaItems.map((i) => i.id)
    if (ids.includes(mediaId)) return null
    return (
      <MediaOptionsDropdown
        viewHandler={() => {
          setMediumId(mediaId)
          $(`#${mediumModalId}`).foundation('open')
          $(`#${mediumModalId}`).on('closed.zf.reveal', () => {
            // stop playing:
            setMediumId(null)
          })
        }}
        downloadHandler={() => {
          const item = data.media.find((m) => m.id === mediaId)
          window.location.href = item.originalUrl
        }}
        copyHandler={() => {
          setMediumId(mediaId)
          $(`#${id}-copyMediumModal`).foundation('open')
        }}
        replaceHandler={() => {
          setMediumId(mediaId)
          $(`#${id}-replaceModal`).foundation('open')
        }}
        deleteHandler={() => {
          setMediumId(mediaId)
          $(`#${id}-deleteSingleModal`).foundation('open')
        }}
        id={mediaId}></MediaOptionsDropdown>
    )
  }

  const renderUploadButton = () => {
    return (
      <span className="cell shrink">
        <button
          data-open={`${id}-uploadMediaModal`}
          className="button primary mb-0"
          title="Upload Media">
          <FaUpload />
        </button>
      </span>
    )
  }

  const modalCloseButton = () => {
    if (!ref.current) return <></>
    const id = ref.current.closest('.o-modal--custom').id
    return (
      <span
        className="flex-container align-middle text-stable-dark pr-1 pl-2 cursor-pointer"
        data-close={id}>
        <FaTimes />
      </span>
    )
  }

  const tableButtonBar = (
    <>
      <span className="cell shrink">
        {preferences.showTable ? (
          <TableColumnsDropdown content={content} />
        ) : null}
      </span>
      <span className="cell shrink button-group o-button-group no-gaps mr-1">
        <button
          id="toggle-view-grid"
          className={`button hollow secondary
              ${!preferences.showTable ? 'active' : ''}`}
          onClick={() => {
            setPreferences({
              ...preferences,
              showTable: false,
            })
          }}>
          <FaThLarge />
        </button>
        <button
          id="toggle-view-table"
          className={`button hollow secondary
            ${preferences.showTable ? 'active' : ''}`}
          onClick={() => {
            setPreferences({
              ...preferences,
              showTable: true,
            })
          }}>
          <FaListUl />
        </button>
      </span>
      {!isLink && renderUploadButton()}
      {isLink && modalCloseButton()}
    </>
  )

  const getPageContent = () => {
    if (showEmptyState)
      return (
        <FilterEmptyState
          type="media files"
          icon={<FaVideoSlash />}
          clickHandler={() => {
            setSearchQuery('')
            updateStore(mediaStore, { filterOption: '' })
            setShowEmptyState(false)
          }}
        />
      )
    if (preferences.showTable) {
      return (
        <Table
          data={sortedData}
          headers={tableHeaders.filter(
            (i) => !preferences.filteredColumns.includes(i)
          )}
          selectable={!isLink}
          selectedItems={selectedItems}
          selectAllHandler={(ids) => setSelectedItems(ids)}
          sortOn={preferences.sortOn}
          sortHandler={(value) =>
            sortHandler(value, preferences, setPreferences)
          }
          rowHandler={!isLink && openHandler}
          rowSuffix={optionsDropdownHandler}
          checkboxHandler={checkboxHandler}
          type="media"
        />
      )
    }
    return (
      <Grid
        data={sortedData}
        selectedItems={selectedItems}
        selectable={!isLink}
        cardHandler={openHandler}
        checkboxHandler={checkboxHandler}
        cardMenu={optionsDropdownHandler}
      />
    )
  }

  const getTitle = () => {
    if (isLink) return 'Link media file'
    const { length } = [...sortedData]
    if (length === 1) return '1 Media file'
    return `${length} Media files`
  }

  const tableHeader = (
    <div
      className={`grid-container fluid ${isLink ? 'pr-3 pl-3 pb-2-5 border-bottom mb-2' : ''}`}>
      <span className={`grid-x o-table__title ${isLink ? 'mb-0' : ''}`}>
        <span className="cell small-12 medium-shrink flex-container align-middle mr-3">
          <h2
            data-testid="table-header-title"
            className="cell medium-shrink mb-0 mr-1">
            {getTitle()}
          </h2>
        </span>
        <span className="cell small-12 medium-auto grid-x">
          {tableSearchBar()}
        </span>
        <span className="cell small-12 medium-shrink grid-x">
          {tableButtonBar}
        </span>
      </span>
    </div>
  )

  const toastContent = (
    <Dropdown
      id="dropup-toast"
      position="top"
      alignment="right"
      offset={{ left: '17px' }}
      button={
        <button
          className="cursor-pointer h-100 outline-none"
          data-toggle="dropup-toast">
          <FaEllipsisH />
        </button>
      }>
      <>
        <li
          className="o-dropdown__list-item"
          data-open={`${id}-copyMultipleMediumModal`}>
          Copy files to...
        </li>
        <hr className="mt-1 mb-1" />
        <li
          className="o-dropdown__list-item"
          data-open={`${id}-deleteMultipleModal`}>
          Delete files
        </li>
      </>
    </Dropdown>
  )
  const loadModals = () => {
    const appendTo = `#${id}-modals`
    return (
      <>
        <div id={`${id}-modals`} />
        <Modal id={mediumModalId} appendTo={appendTo} width="90%">
          <MediumProvider>
            <MediumModal
              mediumId={mediumId}
              data-close
              setMediumId={setMediumId}
              mediumIds={[...sortedData].reduce((a, m) => {
                if (['COMPLETED', 'PROGRESSING'].includes(m.state?.value))
                  a.push(m.id)
                return a
              }, [])}
              reactFlow={reactFlow}
              openSceneCallback={openSceneCallback}
            />
          </MediumProvider>
        </Modal>
        <CopyMediumModal
          id={`${id}-copyMediumModal`}
          appendTo={appendTo}
          currentScenario={scenarioId}
          mediumIds={[mediumId]}
          callback={() => {
            setSelectedItems([])
            refetch()
          }}
        />
        <CopyMediumModal
          id={`${id}-copyMultipleMediumModal`}
          appendTo={appendTo}
          currentScenario={scenarioId}
          mediumIds={[...selectedItems]}
          callback={() => {
            setSelectedItems([])
            refetch()
          }}
        />
        <DeleteMediumModal
          id={`${id}-deleteMultipleModal`}
          appendTo={appendTo}
          mediumIds={[...selectedItems]}
          callback={() => {
            deleteCallback([...selectedItems])
            refetch()
            setSelectedItems([])
          }}
        />
        <DeleteMediumModal
          id={`${id}-deleteSingleModal`}
          appendTo={appendTo}
          mediumIds={[mediumId]}
          callback={() => {
            deleteCallback([mediumId])
            refetch()
            setMediumId(null)
          }}
        />
        <ReplaceMediumModal
          id={`${id}-replaceModal`}
          appendTo={appendTo}
          medium={data?.media?.find((m) => m.id === mediumId)}
          callback={() => {
            refetch()
            setSelectedItems([])
          }}
        />
        <MediaUploadModal
          id={`${id}-uploadMediaModal`}
          appendTo={appendTo}
          uploadFilesHandler={uploadFilesHandler}
          legacyFlow={legacyFlow}
        />
      </>
    )
  }

  if (showEmptyScenario) {
    let c = (
      <MediaEmptyState
        setUploadMediaItems={uploadFilesHandler}
        isLink={isLink}
        id={id}
      />
    )
    if (uploadMediaItems.length) {
      c = (
        <div className="grid-container fluid">
          <Table
            data={getUploadData()}
            headers={tableHeaders.filter(
              (i) => !preferences.filteredColumns.includes(i)
            )}
            selectable
            selectedItems={selectedItems}
            selectAllHandler={(ids) => setSelectedItems(ids)}
            sortOn={preferences.sortOn}
            placeholderRows={uploadMediaItems.length}
            sortHandler={(value) =>
              sortHandler(value, preferences, setPreferences)
            }
            type="media"
          />
        </div>
      )
    }

    return (
      <div id={`${id}-not-found`} ref={ref}>
        {loadModals()}
        {tableHeader}
        {c}
      </div>
    )
  }

  const pageContent = (
    <>
      {tableHeader}
      <div
        className={`grid-container fluid o-table--page-container ${isLink ? 'pl-3 pr-3' : ''}`}
        style={preferences.showTable ? {} : { overflow: 'hidden' }}>
        {getPageContent()}
      </div>
    </>
  )

  return (
    <div id={id} className="h-100" ref={ref}>
      {loadModals()}
      {isLink ? pageContent : pageContent}

      <Toast
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'files' : 'file'}
        dismissable
        closeHandler={() => {
          dismissToastHandler()
          setTimeout(() => {
            setSelectedItems([])
          }, 10)
        }}
        isClosing={dismissToast}>
        {toastContent}
      </Toast>
    </div>
  )
}
export default withApollo(MediaTable)
