import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FaArrowRight, FaArrowLeft, FaRandom } from 'react-icons/fa'
import MediumTitle from './partials/MediumTitle'
import { getMediumQuery } from '../../../apollo/query/media'
import FileInformation from './FileInformation'
import SceneInformation from './SceneInformation'
import MediumVideoPlayer from './partials/MediumVideoPlayer'
import TrimVideoRow from './partials/TrimVideoRow'
import { MediumContext } from './MediumContext'
import MediumControlsRow from './partials/MediumControlsRow'
import { setNode } from '../../FlowV2/helpers/nodeHelper'

const MediumModal = ({
  mediumId,
  mediumIds,
  setMediumId,
  openSceneCallback,
  reactFlow,
}) => {
  const {
    medium: [medium, setMedium],
    time: [, setTime],
    controls: [controls, setControls],
  } = useContext(MediumContext)

  const [blockNavigation, setBlockNavigation] = useState(false)
  const { error, refetch } = useQuery(getMediumQuery, {
    fetchPolicy: 'network-only',
    variables: { id: mediumId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      const n = { ...d.media[0] }
      setMedium(n)
    },
  })

  const navigationHandler = (next) => {
    const currentIndex = mediumIds.findIndex((i) => i === mediumId)
    const nextIndex =
      (mediumIds.length + (currentIndex + (next ? 1 : -1))) % mediumIds.length
    setControls({ ...controls, isPlaying: false })
    setTime(0)
    setMediumId(mediumIds[nextIndex])
  }

  const navigationKeyHandler = (e) => {
    if (!blockNavigation) {
      if (e.key === 'ArrowLeft') navigationHandler(false)
      if (e.key === 'ArrowRight') navigationHandler(true)
    }
  }

  useEffect(() => {
    if (!mediumId) {
      setMedium(null)
      setTime(0)
      setControls({ ...controls, isPlaying: false })
    }
    document.addEventListener('keydown', navigationKeyHandler, true)
    return () => {
      document.removeEventListener('keydown', navigationKeyHandler, true)
    }
  }, [mediumId, blockNavigation])

  useEffect(() => {
    const interval = setInterval(() => {
      const hasIncompleteMedia =
        medium && ['QUEUED', 'PROGRESSING'].includes(medium.state)
      if (hasIncompleteMedia) refetch()
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [medium])

  if (!mediumId || !medium) return null

  if (error) throw new Error(error.message, error)

  const isVideo = medium.kind.includes('VIDEO')

  const renderSceneInformation = () => {
    if (
      ['VIDEO_180', 'IMAGE_180', 'FLAT_IMAGE', 'FLAT_VIDEO'].includes(
        medium.kind
      )
    )
      return
    return (
      <SceneInformation
        refetch={refetch}
        updateVideoCallback={updateVideo}
        openSceneCallback={openSceneCallback}
      />
    )
  }

  const updateVideo = (nodeId, video) => {
    if (!reactFlow) return
    const node = reactFlow.getNodes().find((n) => n.id === nodeId)
    setNode(reactFlow, {
      ...node,
      data: {
        ...node.data,
        hasVideo: !!video,
        video,
      },
    })
  }

  const updateMedium = (medium) => {
    if (!reactFlow) return
    let nodes = reactFlow.getNodes()
    const {
      width,
      height,
      duration,
      name,
      fps,
      size,
      state,
      stereoscopic,
      playUrl,
      trimEnabled,
      trimStart,
      trimEnd,
    } = medium
    medium.linkedToScenes.forEach((scene) => {
      const index = nodes.findIndex((n) => n.id === scene.id)
      nodes[index].data.video = {
        ...nodes[index].data.video,
        width,
        height,
        duration,
        name,
        fps,
        size,
        state,
        stereoscopic,
        playUrl,
        trimEnabled,
        trimStart,
        trimEnd,
      }
    })

    reactFlow.setNodes(nodes)
  }

  const renderImage = () => {
    if (medium.state === 'COMPLETED')
      return (
        <img
          src={medium.previewUrl}
          alt={medium.title}
          className="w-100 h-100 bg-dark"
          style={{
            maxHeight: 'calc(80vh - 100px)',
            objectFit: 'contain',
          }}
        />
      )
    return (
      <div
        className="cursor-pointer loading-dual-ring-container w-100"
        style={{
          height: 'calc(80vh - 100px)',
        }}>
        <div className="loading-dual-ring">
          <FaRandom />
        </div>
      </div>
    )
  }

  return (
    <div key={mediumId} data-testid="medium-modal" className="grid-y">
      <div className="">
        <MediumTitle setBlockNavigation={setBlockNavigation} />
      </div>
      {mediumIds?.length > 0 && (
        <>
          <div
            data-testid="nav-button--back"
            className="o-modal__navigation left"
            onClick={() => navigationHandler(false)}>
            <span className="icon">
              <FaArrowLeft />
            </span>
          </div>
          <div
            data-testid="nav-button--next"
            className="o-modal__navigation right"
            onClick={() => navigationHandler(true)}>
            <span className="icon">
              <FaArrowRight />
            </span>
          </div>
        </>
      )}
      {/* main area */}
      <div className="grid-x">
        <div
          className="cell xlarge-8  o-modal__column--main"
          data-testid="main-column">
          <div className="cell small-12">
            {isVideo ? (
              <>
                <MediumVideoPlayer />
                {medium.state === 'COMPLETED' && (
                  <>
                    <MediumControlsRow />
                    <TrimVideoRow updateMediumCallback={updateMedium} />
                  </>
                )}
              </>
            ) : (
              renderImage()
            )}
          </div>
          <div className="cell small-12 hide-for-xxlarge">
            {renderSceneInformation()}
            <div className="hide-for-xlarge pl-3 pr-3">
              <FileInformation medium={medium} />
            </div>
          </div>
        </div>
        <div className="cell auto show-for-xlarge hide-for-xxlarge pl-3 pr-3">
          <FileInformation medium={medium} />
        </div>
        <div className="cell shrink show-for-xxlarge xlarge-4 xlarge-order-3 xxlarge-order-2">
          {renderSceneInformation()}
          <div className="pl-3 pr-3">
            <FileInformation medium={medium} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediumModal
