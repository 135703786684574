import React, { useContext, useRef, useEffect } from 'react'
import RangeInput from '../../UI/Form/RangeInput'
import { MediumContext } from '../../Media/Modal/MediumContext'
import { convertMilliSeconds } from '../../../utils/format'

const EditorTimeSlider = () => {
  const {
    medium: [medium],
    controls: [controls],
    time: [time, setTime],
    currentVideoRef,
  } = useContext(MediumContext)

  const updateInterval = useRef(null)

  const intervalHandler = () => {
    let currentTimeValue = currentVideoRef.current.currentTime
    if (medium.trimEnabled) {
      currentTimeValue = Number(currentTimeValue) - Number(medium.trimStart)
      if (currentVideoRef.current.currentTime >= medium.trimEnd) {
        // reached endTimes
        currentVideoRef.current.pause()
        currentVideoRef.current.dispatchEvent(new CustomEvent('ended-on-trim'))
      }
    }
    // setTime(currentTimeValue * 1000) // setTime is in ms
  }

  useEffect(() => {
    if (controls.isPlaying && medium.state === 'COMPLETED') {
      updateInterval.current = setInterval(intervalHandler, 1000 / medium.fps)
    }
    return () => clearInterval(updateInterval.current)
  }, [controls.isPlaying, medium.id])

  useEffect(() => {
    setTime(medium.trimEnabled && medium.trimStart ? medium.trimStart * 1000 : 0)
  }, [medium?.id])

  if (!medium) return null

  const getTime = () => {
    if (medium.trimStart) {
      return (currentVideoRef.current.currentTime - medium.trimStart) * 1000
    }
    return time
  }

  const updateSliderHandler = (value) => {
    let actualValue = value / 1000
    if (actualValue * 1000 === 0) actualValue = 0.001
    if (medium.trimEnabled) {
      const startVideoTime = (
        Number(actualValue) + Number(medium.trimStart)
      ).toFixed(2)
      currentVideoRef.current.currentTime = startVideoTime
      setTime(startVideoTime * 1000)
    } else {
      currentVideoRef.current.currentTime = Number(actualValue)
      setTime(actualValue * 1000)
    }
  }

  return (
    <div id="time-slider-editor" className="cell small-6 auto large-7 end">
      <RangeInput
        redThumb
        noBackground
        showLabelsOnHoverOnly
        formatLabel={(v) => convertMilliSeconds(v)}
        minValue={0}
        maxValue={
          medium.trimEnabled
            ? (medium.trimEnd - medium.trimStart) * 1000 || medium.duration
            : Number(medium.duration)
        }
        showLabels={false}
        value={getTime()}
        setValue={updateSliderHandler}
      />
    </div>
  )
}

export default EditorTimeSlider
