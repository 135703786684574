import React from 'react'
import { useReactFlow } from 'reactflow'
import { FaQuestionCircle } from 'react-icons/fa'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import useSelectedNode from '../../hooks/useSelectedNode'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import DataTooltip from '../../../UI/DataTooltip'
import { setNode } from '../../helpers/nodeHelper'
import SizeDropdown from '../../../Editor/Video/Tabs/Partials/SizeDropdown'
import config from '../../config/config'

const getSettingsToggle = (text, id, checked, update, title) => {
  return (
    <div className="c-video__editor--elements--settings--row mb-0 switch-settings">
      <div className="o-switch">
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={checked}
          onChange={update}
          className="o-switch__input"
        />
        <label htmlFor={id} className="o-switch__paddle"></label>
      </div>
      <span className="ml-1">{text}</span>
      <span className="ml-0-5">
        {title && (
          <DataTooltip title={title}>
            <FaQuestionCircle className="text-stable" />
          </DataTooltip>
        )}
      </span>
    </div>
  )
}

const ElementSettings = ({ element, labelElements = [] }) => {
  const { updateElement } = useAddUpdateElement()
  const selectedNode = useSelectedNode()
  const reactFlow = useReactFlow()

  const showVideoEditor = useShowVideoEditor()

  if (!showVideoEditor) return null

  const handleSizeChange = (labelSize, elements, name = 'labelSize') => {
    Promise.all(
      elements.map((el) =>
        updateElement({
          variables: {
            ...el,
            [name]: labelSize,
          },
        })
      )
    ).then((responses) => {
      const newNode = {
        ...selectedNode,
        selected: true,
        data: {
          ...selectedNode.data,
          elements: [
            ...(selectedNode.data.elements.filter(
              (el) => !responses.find((r) => r.id === el.id)
            ) ?? []),
            ...responses,
          ],
        },
      }
      setNode(reactFlow, newNode)
    })
  }

  const renderLabelSize = (
    label = 'Label size',
    labelElements,
    options,
    name = 'labelSize'
  ) => (
    <div className="flex-container align-middle align-justify">
      <div className="mr-1">{label}</div>
      <SizeDropdown
        element={labelElements?.[0] ?? element}
        onChange={(labelSize) =>
          handleSizeChange(
            labelSize,
            labelElements?.length ? labelElements : [element],
            name
          )
        }
        label={label}
        options={options}
        name={name}
      />
    </div>
  )
  const renderLookRowToggle = () => (
    <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
      {getSettingsToggle(
        'Show in look direction',
        'toggleLookDirection',
        element.showInLookDirection,
        () =>
          updateElement({
            variables: {
              ...element,
              showInLookDirection: !element.showInLookDirection,
            },
          }),
        'Position this element where the trainee enters the scene'
      )}
    </div>
  )

  const renderHideLabel = (label = 'Hide label') => {
    return (
      <div className="c-video__editor--elements--settings--row mb-0 switch-settings">
        <div className="o-switch">
          <input
            type="checkbox"
            name="showLabel"
            id={`showLabel-${element.id}`}
            checked={!element.showLabel}
            onChange={() => {
              updateElement({
                variables: {
                  ...element,
                  showLabel: !element.showLabel,
                },
              })
            }}
            className="o-switch__input"
          />
          <label
            htmlFor={`showLabel-${element.id}`}
            className="o-switch__paddle"></label>
        </div>
        <span className="ml-1">{label}</span>
      </div>
    )
  }

  const renderOpenOnFadeIn = () => {
    return (
      <div className="c-video__editor--elements--settings--row mb-0 switch-settings">
        <div className="o-switch">
          <input
            type="checkbox"
            name="openOnFadeIn"
            id={`openOnFadeIn-${element.id}`}
            checked={element.openOnFadeIn}
            onChange={() => {
              updateElement({
                variables: {
                  ...element,
                  openOnFadeIn: !element.openOnFadeIn,
                },
              })
            }}
            className="o-switch__input"
          />
          <label
            htmlFor={`openOnFadeIn-${element.id}`}
            className="o-switch__paddle"></label>
        </div>
        <span className="ml-1">Open automatically when elements fade in</span>
      </div>
    )
  }

  const settingsMap = {
    HOTSPOT: () => {
      element.hotspotSize = element.hotspotSize || config.defaultHotspotSize
      return (
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
          {renderLabelSize(
            'Hotspot size',
            [],
            [
              { value: 'SMALL', label: 'Small' },
              { value: 'MEDIUM', label: 'Medium' },
              { value: 'LARGE', label: 'Large' },
            ],
            'hotspotSize'
          )}
          {renderLabelSize('Label size')}
        </div>
      )
    },
    MEDIAPANEL: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
          {renderHideLabel()}
          {renderLabelSize()}
        </div>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
          {renderOpenOnFadeIn()}
        </div>
      </>
    ),
    BUTTON: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
        {renderLabelSize()}
      </div>
    ),
    DIRECTION: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
        {renderHideLabel()}
        {renderLabelSize()}
      </div>
    ),
    TOOLTIP: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
          {renderLabelSize('Tooltip size')}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    INFORMATION: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top">
          {renderLabelSize('Information and answer size', [
            element,
            ...labelElements,
          ])}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    MPC: () => (
      <>
        <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
          {renderLabelSize('Question size')}
          {renderLabelSize('Answer size', labelElements)}
        </div>
        {renderLookRowToggle()}
      </>
    ),
    TIMER: () => (
      <div className="pt-1 pr-2 pl-2 pb-1 border-light-top flex-container align-middle align-justify">
        {renderHideLabel('Hide timer')}
      </div>
    ),
  }
  const kind = element.groupKind || element.kind
  if (!settingsMap?.[kind]) return null
  return (
    <div
      className="c-video__editor--elements--settings-container"
      style={
        element.groupUuid ? {} : { marginRight: '-20px', marginLeft: '-20px' }
      }>
      {settingsMap[kind]()}
    </div>
  )
}

export default ElementSettings
