import React, { useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { FaChevronDown } from 'react-icons/fa'
import Dropdown from './Dropdown'
import { truncate } from '../../../utils/helpers/javascript'
import SearchableDropdown from './SearchableDropdown'
import Checkbox from '../Form/Checkbox'

const Filter = ({
  text,
  icon,
  filterHandler,
  selected: defaultSelecteds = [],
  filterOptions,
  searchable,
  searchPlaceholder = 'Search...',
  dropdownPosition = 'bottom',
  dropdownAlignment = 'left',
  multiple = false,
  type = 'dropdown',
  footer,
  headerText,
}) => {
  let timer

  const [_showSearchDropdown, setShowSearchDropdown] = useState(false)
  const [selecteds, setSelecteds] = useState([])
  const randomId = uuidV4()

  const handleFilter = (checked, value, only = false) => {
    let results
    if (only) {
      results = [value]
    } else {
      results = checked
        ? value === 'All'
          ? ['All']
          : [...selecteds, value]
        : selecteds.includes('All')
          ? value === 'All'
            ? []
            : filterOptions
                .filter((f) => (f.value ?? f) !== value && f !== 'All')
                .map((f) => f.filterRender ?? f.render ?? f)
          : selecteds.filter((s) => s !== value)
      if (value !== 'All' && results.length === filterOptions.length - 1)
        results = ['All']
    }
    if (multiple) setSelecteds(results)
    else filterHandler(results)
  }

  const showSearchDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => setShowSearchDropdown(true), 500)
  }

  const hideDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setShowSearchDropdown(false)
    }, 500)
  }

  const button = () => {
    if (type === 'menu')
      return (
        <li
          className="o-dropdown__list-item o-dropdown__item--open-dropdown is-dropdown-submenu-parent cursor-pointer"
          data-open={randomId}>
          <span className="o-dropdown__link-aside" />
          <span>{text}</span>
        </li>
      )

    const selectedFilterOption = filterOptions.find(
      (option) => option === selecteds[0] || option?.value === selecteds[0]
    )
    return (
      <button
        className="button secondary hollow mb-0"
        data-toggle={randomId}
        onClick={showSearchDropdown}>
        <span>{text}</span>
        <span
          className="text-dark text-truncate"
          style={{ margin: '0px 8px', maxWidth: '150px' }}>
          {typeof selecteds === 'object'
            ? selecteds.length > 1
              ? selecteds.join(', ')
              : (selectedFilterOption?.filterRender ??
                selectedFilterOption?.render ??
                selecteds[0])
            : selecteds}
        </span>
        <span className="trailing" style={{ paddingTop: '2px', color: '#333' }}>
          {icon || <FaChevronDown />}
        </span>
      </button>
    )
  }

  const renderMultipleFooter = () => {
    return (
      <div className="flex-container align-justify align-middle w-100">
        <span className="text-normal text-italic text-stable-dark u-capitalize">
          {searchable && selecteds.length > 0 && (
            <>
              {selecteds.includes('All') ? 'All' : selecteds.length} {text}
              {selecteds.length > 1 || selecteds.includes('All')
                ? 's'
                : ''}{' '}
              selected
            </>
          )}
        </span>
        <button
          className="o-button text-normal o-button--auto-width o-button--auto-height"
          onClick={() => {
            if (searchable) setShowSearchDropdown(false)
            else $(`#${randomId}`).foundation('close')
            filterHandler(selecteds)
          }}>
          Confirm
        </button>
      </div>
    )
  }

  const renderFooter = () => {
    if (footer) return footer
    if (multiple) {
      if (!searchable)
        return (
          <div className="o-dropdown__list-item border-top pb-0 mt-1">
            {renderMultipleFooter()}
          </div>
        )
      return renderMultipleFooter()
    }
    return null
  }

  const content = () => {
    const list = filterOptions.map((option, i) => {
      if (option?.isDivider)
        return <li key="divider" className="o-dropdown__divider" />
      const value = option.value ?? option
      const title = option.render ?? option

      return (
        <li
          key={title}
          data-testid={`listitem-${i}`}
          className={`o-dropdown__list-item text-normal ${
            selecteds.includes(value) ? 'text-assertive' : ''
          }`}
          title={title.length > 40 ? title : ''}
          onClick={() => {
            if (!multiple) {
              filterHandler(value === 'All' ? '' : value)
              $(`#${randomId}`).foundation('close')
            }
          }}>
          {multiple ? (
            <Checkbox
              checked={selecteds.includes(value) || selecteds.includes('All')}
              value={title}
              style={{ marginBottom: '0px' }}
              clickHandler={(checked) => handleFilter(checked, value)}
            />
          ) : (
            truncate(title, 40, true)
          )}
        </li>
      )
    })

    return (
      <>
        {headerText && (
          <div className="o-dropdown__list-item text-small text-stable-dark cursor-default">
            {headerText}
          </div>
        )}
        {list}
        {renderFooter()}
      </>
    )
  }

  useEffect(() => {
    if (defaultSelecteds?.length)
      setSelecteds(
        typeof defaultSelecteds === 'string'
          ? [defaultSelecteds]
          : defaultSelecteds
      )
  }, [defaultSelecteds])

  if (!searchable)
    return (
      <div>
        <Dropdown
          offset={{ [dropdownPosition]: '2px' }}
          button={button()}
          id={randomId}
          position={dropdownPosition}
          alignment={dropdownAlignment}>
          {content()}
        </Dropdown>
      </div>
    )

  return (
    <div onMouseLeave={hideDropdown} onMouseEnter={showSearchDropdown}>
      {button()}
      <SearchableDropdown
        position={dropdownPosition}
        style={{
          width: '540px',
          top: type === 'menu' ? '-40px' : '0',
          left: type === 'menu' ? 'calc(100% + 2px)' : '0',
        }}
        show={_showSearchDropdown}
        placeholder={searchPlaceholder}
        data={filterOptions.map((item) => {
          const value = item.value ?? item
          const title = item.render ?? item
          return {
            value,
            render: multiple ? (
              <div className="flex-container align-middle align-justify">
                <Checkbox
                  checked={
                    selecteds === value ||
                    selecteds.includes(value) ||
                    selecteds.includes('All')
                  }
                  value={title}
                  style={{ marginBottom: '0px' }}
                  clickHandler={(checked) => handleFilter(checked, value)}
                  truncate
                />
                {value !== 'All' && (
                  <a
                    className="button-only"
                    onClick={(checked) => handleFilter(checked, value, true)}>
                    Only
                  </a>
                )}
              </div>
            ) : (
              title
            ),
          }
        })}
        clickHandler={(option) => {
          if (!multiple) {
            hideDropdown()
            filterHandler(option.value !== 'All' ? option.value : '')
          }
        }}
        hideHandler={hideDropdown}
        footer={renderFooter()}
      />
    </div>
  )
}

export default Filter
