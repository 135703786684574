import React, { useEffect, useState } from 'react'
import { BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import sanitizeHTML from '../../../utils/helpers/sanitizeHTML'
import ToolbarPlugin from './ToolbarPlugin'

const EditorContent = ({ id, value, focused }) => {
  const { editor } = useCurrentEditor()
  const [isInitialFocus, setIsInitialFocus] = useState(false)

  useEffect(() => {
    if (editor && (!isInitialFocus || !focused)) {
      editor.commands.clearContent()
      editor.commands.setContent(value ? value : '<p></p>')
    }
  }, [id, value, isInitialFocus, focused])

  useEffect(() => {
    if (focused) {
      editor.commands.focus('end')
      setIsInitialFocus(true)
    }
  }, [focused])

  useEffect(() => {
    setIsInitialFocus(false)
  }, [id])

  return (
    <BubbleMenu>
      <div className="bubble-menu">
        <ToolbarPlugin />
      </div>
    </BubbleMenu>
  )
}

const HtmlEditor = ({
  id,
  value,
  focused,
  containerProps,
  onUpdate,
  placeholder,
}) => {
  return (
    <EditorProvider
      content={sanitizeHTML(value)}
      extensions={[
        Bold,
        Italic,
        Document,
        Paragraph,
        Text,
        Underline,
        Placeholder.configure({
          placeholder: !value ? placeholder : '',
        }),
      ]}
      injectCSS={false}
      editable={true}
      autofocus={focused}
      editorContainerProps={containerProps}
      onUpdate={onUpdate}>
      <EditorContent id={id} value={value} focused={focused} />
    </EditorProvider>
  )
}

export default HtmlEditor
