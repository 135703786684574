import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  FaThLarge,
  FaListUl,
  FaCopy,
  FaChevronDown,
  FaTrash,
  FaImage,
} from 'react-icons/fa'
import TableHeader from '../components/UI/TableHeader'
import {
  deleteScenarioMutation,
  unpublishScenarioMutation,
  unpublishTestScenarioMutation,
  getScenariosQuery,
  migrateAllScenariosMutation,
  migrateScenarioMutation,
} from '../apollo/query/scenarios'
import { clientsQuery } from '../apollo/query/user'
import LoadingSpinner from '../components/UI/LoadingSpinner'
import withApollo from '../hooks/withApollo'
import { getStoreQuery } from '../apollo/query/store'
import { updateScenarioStore } from '../utils/helpers/graphql'
import Grid from '../components/Grid'
import {
  dateFormat,
  dateFormatWithoutTZConversion,
  sortOnKey,
} from '../utils/format'
import Table from '../components/Table'
import Toast from '../components/UI/Notifications/Toast'
import OptionsDropdown from '../components/Scenarios/OptionsDropdown'
import Checkbox from '../components/UI/Form/Checkbox'
import Filter from '../components/UI/Menu/Filter'
import AddScenarioModal from '../components/Scenarios/Modal/AddScenarioModal'
import EditScenarioModal from '../components/Scenarios/Modal/EditScenarioModal'
import Modal from '../components/UI/Modal'
import cache from '../apollo/cache'
import DuplicateScenarioModal from '../components/Scenarios/Modal/DuplicateScenarioModal'
import { default as S } from '../utils/lang/en.json'
import ScenariosEmptyPage from './Scenarios/ScenariosEmptyPage'
import Delay from '../utils/helpers/Delay'
import TableColumnsDropdown from '../components/UI/TableColumnsDropdown'
import useCurrentUser from '../hooks/useCurrentUser'
import usePreferences from '../hooks/usePreferences'
import { filterColumnsHandler, sortHandler } from '../components/tableFunctions'
import TableSearchBar from '../components/UI/Table/TableSearchBar'
import TableAddButton from '../components/UI/Table/TableAddButton'
import { addSuccessAlert } from '../utils/helpers/alerts'
import { handleApolloError } from '../utils/errors'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import TraineeUserGroup from '../components/Trainees/Table/TraineeUserGroup'
import { getGroupNamesQuery } from '../apollo/query/groups'
import MigrateModal from '../components/FlowV2/components/FlowEditor/Publish/MigrateModal'

const getAttemptCount = (scenario) =>
  scenario.attemptsCount
    ? `${scenario.attemptsCount} attempt${
        scenario.attemptsCount === 1 ? '' : 's'
      }`
    : '-'

const Scenarios = () => {
  const [preferences, setPreferences] = usePreferences('scenarios')
  const { loading, data, error, startPolling, stopPolling, refetch } =
    useQuery(getScenariosQuery)

  const { loading: loadingGroups, data: groupsData } = useQuery(
    getGroupNamesQuery,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const [currentScenarios, setCurrentScenarios] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [dismissToast, setDismissToast] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [showEmptyState, setShowEmptyState] = useState(false)
  const migratedScenarioId = useRef(null)

  const [currentUser, currentClient, currentRole] = useCurrentUser()

  const { data: store } = useQuery(getStoreQuery)
  const { scenarioStore } = store
  const canViewAttempts = ['OWNER', 'MANAGER', 'INSTRUCTOR'].includes(
    currentRole
  )
  const canDuplicateToWorkspace =
    ['OWNER', 'MANAGER'].includes(currentRole) || currentUser?.administrator
  const hybridNewFlow = currentClient?.features.includes('HYBRID_NEW_FLOW')

  const [getClients, { data: clients }] = useLazyQuery(clientsQuery)

  const getClientsForDuplication = () => {
    if (canDuplicateToWorkspace) {
      getClients()
    }
  }

  const [deleteScenario] = useMutation(deleteScenarioMutation, {
    onError: handleApolloError,
    update: (_, { data: d }) => {
      const current = cache.readQuery({ query: getScenariosQuery })
      const { id } = d.removeScenario

      addSuccessAlert({
        title: S.notifications.scenarios.delete,
        subtitle: current.scenarios.find((i) => i.id === id).name,
        icon: 'thrash',
      })

      cache.writeQuery({
        query: getScenariosQuery,
        data: { scenarios: current.scenarios.filter((i) => i.id !== id) },
      })
    },
  })

  const [unpublishScenario] = useMutation(unpublishScenarioMutation, {
    onError: handleApolloError,
    update: (_, { data: d }) => {
      const current = cache.readQuery({ query: getScenariosQuery })
      const { id } = d.unpublishScenario
      const index = current.scenarios.findIndex((item) => item.id === id)
      let newScenario = current.scenarios.find((item) => item.id === id)
      const { statuses } = newScenario
      const newStatus = statuses.filter((i) => i !== 'PUBLISHED')
      const newScenarios = [...current.scenarios]
      newScenario = { ...newScenario, statuses: newStatus }
      newScenarios[index] = newScenario

      addSuccessAlert({
        title: S.notifications.scenarios.unpublish,
        subtitle: current.scenarios.find((i) => i.id === id).name,
        icon: 'undo',
      })

      cache.writeQuery({
        query: getScenariosQuery,
        data: { scenarios: newScenarios },
      })
    },
  })

  const [unpublishTestScenario] = useMutation(unpublishTestScenarioMutation, {
    onError: handleApolloError,
    update: (_, { data: d }) => {
      const current = cache.readQuery({ query: getScenariosQuery })
      const { id } = d.unpublishTestScenario
      const index = current.scenarios.findIndex((item) => item.id === id)
      let newScenario = current.scenarios.find((item) => item.id === id)
      const { statuses } = newScenario
      const newStatus = statuses.filter((i) => i !== 'TESTING')
      const newScenarios = [...current.scenarios]
      newScenario = { ...newScenario, statuses: newStatus }
      newScenarios[index] = newScenario

      addSuccessAlert({
        title: S.notifications.scenarios.unpublishTest,
        subtitle: current.scenarios.find((i) => i.id === id).name,
        icon: 'undo',
      })

      cache.writeQuery({
        query: getScenariosQuery,
        data: { scenarios: newScenarios },
      })
    },
  })

  const [migrateScenario] = useMutation(migrateScenarioMutation, {
    variables: { id: currentScenarios?.[0]?.id },
    onError: handleApolloError,
    onCompleted: (d) => {
      const current = cache.readQuery({ query: getScenariosQuery })
      const id = migratedScenarioId.current
      const index = current.scenarios.findIndex((item) => item.id === id)
      let newScenario = current.scenarios.find((item) => item.id === id)
      const newScenarios = [...current.scenarios]
      newScenario = { ...newScenario, legacyFlow: false }
      newScenarios[index] = newScenario

      addSuccessAlert({
        title: 'Successfully migrated scenario',
      })

      cache.writeQuery({
        query: getScenariosQuery,
        data: { scenarios: newScenarios },
      })

      $('#migrateModal').data('all', '')
      migratedScenarioId.current = null

      openHandler(id)
    },
  })

  // TODO: move to Modal
  const [migrateAllScenarios] = useMutation(migrateAllScenariosMutation, {
    onError: handleApolloError,
    onCompleted: () => {
      addSuccessAlert({
        title: 'Successfully migrated all scenarios',
      })
      $('#migrateModal').data('all', '')
      refetch()
      window.location.reload()
    },
  })

  const handleMigrate = () => {
    if ($('#migrateModal').data('all') === 'false') {
      migratedScenarioId.current = currentScenarios?.[0]?.id
      migrateScenario()
    } else migrateAllScenarios()
  }

  useEffect(() => {
    // Start polling during publishing
    if (data) {
      const statuses = data?.scenarios.flatMap((scenario) => [
        ...scenario.statuses,
        ...(scenario.testScenario ? [scenario.testScenario.status] : []),
      ])
      const isPublishing =
        statuses.includes('PUBLISHING') ||
        statuses.includes('PUBLISHING_FOR_TESTING')

      if (isPublishing) startPolling(5000)
      if (!isPublishing) stopPolling()
    }
  }, [data])

  if (loading || !preferences || !currentUser || loadingGroups)
    return (
      <Delay>
        <LoadingSpinner />
      </Delay>
    )

  if (error) throw new Error(error.message)
  if (!data) return null

  const dismissToastHandler = () => {
    setDismissToast(true)
    setTimeout(() => {
      setDismissToast(false)
    }, 300)
  }

  const renderStatuses = (statuses) => {
    if (statuses.includes('PUBLISHING_FOR_TESTING')) {
      return (
        <label
          className={`o-label o-label--status o-label--assertive ${
            !preferences.showTable && 'o-label--centered'
          }`}>
          {S.dataTypes.transform.state.PUBLISHING_FOR_TESTING}
        </label>
      )
    }

    if (statuses.includes('PUBLISHING')) {
      return (
        <label
          className={`o-label--status o-label--assertive ${
            !preferences.showTable && 'o-label--centered'
          }`}>
          {S.dataTypes.transform.state.PUBLISHING}
        </label>
      )
    }

    return statuses.map((status, i) => {
      if (status === 'MODIFIED') return null
      return (
        <label
          key={i}
          className={`o-label--status ${
            (status === 'PUBLISHED' && 'o-label--energized') ||
            (status === 'TESTING' && 'o-label--royal')
          }`}>
          {S.dataTypes.transform.state[status]}
        </label>
      )
    })
  }

  const renderNameLabel = (value, scenario, isSubtitle = false) => {
    const modified = scenario.statuses.includes('MODIFIED')
    const legacyFlow = scenario.legacyFlow

    if (modified || legacyFlow) {
      return (
        <div className="flex-container align-middle whitespace-nowrap">
          <span className="mr-2">
            {isSubtitle ? (
              value
            ) : (
              <a onClick={() => openHandler(scenario.id)}>{value}</a>
            )}
          </span>
          <div className="flex-container align-middle flex-wrap">
            {legacyFlow && (
              <label className="o-label--custom o-label--stable-lighter text-small text-bold text-stable-dark mb-0-5 mr-0-5">
                {S.dataTypes.transform.state.LEGACY_FLOW.toUpperCase()}
              </label>
            )}
            {modified && (
              <label className="o-label--custom o-label--stable-lighter text-small text-bold text-stable-dark">
                {S.dataTypes.transform.state.MODIFIED.toUpperCase()}
              </label>
            )}
          </div>
        </div>
      )
    }
    return isSubtitle ? (
      value
    ) : (
      <a onClick={() => openHandler(scenario.id)}>{value}</a>
    )
  }

  const renderSubtitle = (value, scenario) => {
    return renderNameLabel(value, scenario, true)
  }

  const openMigrateModal = () => {
    $('#migrateModal').data('all', 'false').foundation('open')
  }

  const renderOptions = (id) => {
    return (
      <OptionsDropdown
        id={id}
        currentScenario={data.scenarios.find((i) => i.id === id)}
        setCurrentScenarios={setCurrentScenarios}
        getClients={getClientsForDuplication}
        canViewAttempts={canViewAttempts}
        migrateFlowHandler={openMigrateModal}
      />
    )
  }
  const sortedData = () => {
    const { value, isAscending } = preferences.sortOn
    const { filterOption } = scenarioStore
    const statusFilter = filterOption.toLowerCase()
    let filtered = [...data.scenarios].filter((scenario) => {
      return scenario.name.toLowerCase().includes(searchQuery.toLowerCase())
    })
    filtered = filtered.map((scenario) => {
      const groupNames = groupsData?.groups
        .filter((group) => !group.automated)
        .filter((group) =>
          group.publishedScenarios.some(
            (gs) => gs.id === scenario.publishedScenario?.id
          )
        )
        .map((group) => group.name)

      return {
        ...scenario,
        groupNames,
        groupCount: groupNames?.length,
      }
    })

    switch (statusFilter) {
      case 'published':
        filtered = filtered.filter((item) =>
          item.statuses.includes('PUBLISHED')
        )
        break
      case 'test':
        filtered = filtered.filter((item) => item.statuses.includes('TESTING'))
        break
      case 'unpublished changes':
        filtered = filtered.filter((item) => item.statuses.includes('MODIFIED'))
        break
      case 'no status':
        filtered = filtered.filter((item) => !item.statuses.length)
        break
      default:
        break
    }
    const cards = sortOnKey(filtered || [...data.scenarios], value, isAscending)
    if (!cards.length && !showEmptyState) setShowEmptyState(true)
    if (cards.length && showEmptyState) setShowEmptyState(false)

    return cards.filter(sc => sc).map((sc) => ({
      imageUrl: sc.imageUrl,
      name: {
        value: sc.name,
        render: renderNameLabel(sc.name, sc),
      },
      statuses: {
        value: sc.statuses,
        render: renderStatuses(sc.statuses),
      },
      groupCount: {
        value: 'scenario.groupCount',
        render: (
          <TraineeUserGroup
            count={sc.groupCount}
            names={sc.groupNames}
          />
        ),
      },
      scenesCount: sc.scenesCount,
      attemptsCount: getAttemptCount(sc),

      lastAttemptAt: sc.lastAttemptAt
        ? dateFormatWithoutTZConversion(sc.lastAttemptAt)
        : '-',
      id: sc.id,
      updatedAt: dateFormat(sc.updatedAt),
      createdAt: dateFormat(sc.createdAt),
      isDisabled:
        sc.statuses.includes('PUBLISHING') ||
        sc.statuses.includes('PUBLISHING_FOR_TESTING'),
      subtitle: {
        value: `${sc.scenesCount} Scene${
          sc.scenesCount !== 1 ? 's' : ''
        }`,
        render: renderSubtitle(
          `${sc.scenesCount} Scene${
            sc.scenesCount !== 1 ? 's' : ''
          }`,
          sc
        ),
      },
      imageOverlay: renderStatuses(sc.statuses),
    }))
  }

  const openHandler = (id) => {
    window.location.assign(`scenarios/${id}`)
  }

  const checkboxHandler = (id) => {
    let newSelection = [...selectedItems]
    if (newSelection.includes(id)) {
      newSelection = newSelection.filter((i) => i !== id)
    } else {
      newSelection.push(id)
    }
    if (!newSelection.length) dismissToastHandler()
    return setSelectedItems(() => newSelection)
  }

  const tableHeaders = [
    'name',
    'statuses',
    'groupCount',
    'scenesCount',
    'attemptsCount',
    'lastAttemptAt',
    'updatedAt',
    'createdAt',
  ]

  const handleGridOrder = (value) => {
    const { headers } = S.table.scenarios
    const key = Object.keys(headers).find((k) => headers[k] === value)
    sortHandler(key, preferences, setPreferences)
  }
  const content = () => {
    return tableHeaders.map((item, i) => {
      const disabled = i === 0
      return (
        <li
          key={i}
          className={`mb-0 text-normal o-dropdown__list-item ${
            disabled ? 'disabled' : ''
          }`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault()
              filterColumnsHandler(item, preferences, setPreferences)
            }
          }}>
          <Checkbox
            disabled={disabled}
            style={{ marginBottom: '0px', cursor: 'default' }}
            checked={!preferences.filteredColumns.includes(item)}
            value={S.table.scenarios.headers[item]}
          />
        </li>
      )
    })
  }

  const tableSearchBar = () => (
    <>
      <span className="cell shrink large-auto mr-1 large-order-2">
        <Filter
          text="Status"
          icon={<FaChevronDown />}
          filterHandler={(filter) => {
            updateScenarioStore(scenarioStore, {
              filterOption: filter === 'All' ? '' : filter,
            })
          }}
          selected={scenarioStore.filterOption || 'All'}
          filterOptions={[
            'All',
            'Published',
            'Test',
            'Unpublished changes',
            'No status',
          ]}
        />
      </span>
      <TableSearchBar
        searchQuery={searchQuery}
        showEmptyState={showEmptyState}
        setSearchQuery={setSearchQuery}
        setShowEmptyState={setShowEmptyState}
      />
    </>
  )

  const tableButtonBar = () => {
    const orderList = ['name', 'updatedAt', 'createdAt']
    return (
      <>
        <span className="cell shrink mr-1 mb-0">
          {preferences.showTable ? (
            <TableColumnsDropdown content={content} />
          ) : (
            <Filter
              text="Order by"
              icon={<FaChevronDown />}
              filterHandler={(x) => handleGridOrder(x)}
              selected={S.table.scenarios.headers[preferences.sortOn.value]}
              filterOptions={orderList
                .map((item) => S.table.scenarios.headers[item])
                .sort()}
            />
          )}
        </span>
        <span className="cell shrink button-group o-button-group no-gaps mr-1">
          <button
            id="toggle-view-grid"
            onClick={() => {
              setPreferences({
                ...preferences,
                showTable: false,
              })
            }}
            className={`button hollow secondary ${
              !preferences.showTable && 'active'
            }`}>
            <FaThLarge />
          </button>
          <button
            id="toggle-view-table"
            onClick={() => {
              setPreferences({
                ...preferences,
                showTable: true,
              })
            }}
            className={`button hollow secondary ${
              preferences.showTable && 'active'
            }`}>
            <FaListUl />
          </button>
        </span>
        <TableAddButton dataOpen="addScenarioModal" title="New scenario" />
      </>
    )
  }

  if (!currentClient) return null

  if (!data.scenarios.length) return <ScenariosEmptyPage />

  return (
    <div id="scenarios">
      <EditScenarioModal
        id="editScenarioModal"
        appendTo="#scenarios"
        scenarios={currentScenarios}
      />
      <AddScenarioModal
        id="addScenarioModal"
        hybridNewFlow={hybridNewFlow}
        appendTo="#scenarios"
      />
      <DuplicateScenarioModal
        id="duplicateScenarioModal"
        appendTo="#scenarios"
        scenarios={currentScenarios}
        clients={clients?.clients}
        currentClient={currentClient}
      />
      <MigrateModal
        id="migrateModal"
        appendTo="#scenarios"
        onConfirm={handleMigrate}
        scenarioName={currentScenarios?.[0]?.name}
        onClose={() => {
          setCurrentScenarios([])
          $('#migrateModal').data('all', '')
        }}
      />
      <Modal
        id="unpublishScenario"
        closable={false}
        appendTo="#scenarios"
        textAssertive
        headerText={`Are you sure you want to unpublish ${
          currentScenarios?.length === 1 ? `this scenario?` : `these scenarios?`
        }`}
        subtitle={`${
          currentScenarios?.length === 1 ? `This scenario` : `These scenarios`
        } will also be removed from any course or group it is in.`}
        useDefaultButtons
        submitButtonText="Yes, I'm sure"
        submitHandler={() => {
          currentScenarios.forEach(({ id }) => {
            unpublishScenario({ variables: { id } })
          })
          setSelectedItems([])
        }}
      />
      <Modal
        id="unpublishTestScenario"
        closable={false}
        appendTo="#scenarios"
        textAssertive
        headerText={`Are you sure you want to stop testing ${
          currentScenarios?.length === 1 ? `this scenario?` : `these scenarios?`
        }`}
        subtitle="This action cannot be undone"
        useDefaultButtons
        submitHandler={() => {
          currentScenarios.forEach(({ id }) => {
            unpublishTestScenario({ variables: { id } })
          })
          setSelectedItems([])
        }}
      />
      <Modal
        id="deleteModal"
        appendTo="#scenarios"
        closable={false}
        textAssertive
        headerText={`${
          currentScenarios?.length === 1
            ? `${S.strings.confirmDeleteTextSingle} scenario?`
            : `${S.strings.confirmDeleteText} scenarios?`
        }`}
        subtitle="This action cannot be undone"
        useDefaultButtons
        submitHandler={() => {
          currentScenarios.forEach(({ id }) => {
            deleteScenario({ variables: { id } })
          })
          setSelectedItems([])
        }}
      />
      <div className="grid-container fluid">
        <TableHeader
          title="Scenario"
          length={[...sortedData()].length}
          leftBar={tableSearchBar()}
          rightBar={tableButtonBar()}
        />
      </div>
      <div
        className="o-table--page-container grid-container fluid"
        style={preferences.showTable ? {} : { overflow: 'hidden' }}>
        {
          // eslint-disable-next-line no-nested-ternary
          showEmptyState ? (
            <FilterEmptyState
              type="scenarios"
              icon={<FaImage />}
              clickHandler={() => {
                setSearchQuery('')
                setSelectedItems([])
                updateScenarioStore(scenarioStore, { filterOption: '' })
              }}
            />
          ) : preferences.showTable ? (
            <Table
              data={sortedData()}
              headers={tableHeaders.filter(
                (i) => !preferences.filteredColumns.includes(i)
              )}
              selectable
              selectedItems={selectedItems}
              selectAllHandler={(ids) => setSelectedItems(ids)}
              sortOn={preferences.sortOn}
              sortHandler={(value) =>
                sortHandler(value, preferences, setPreferences)
              }
              checkboxHandler={checkboxHandler}
              type="scenarios"
              rowSuffix={(i) => renderOptions(i)}
            />
          ) : (
            <Grid
              data={sortedData()}
              selectedItems={selectedItems}
              selectable
              cardHandler={openHandler}
              checkboxHandler={checkboxHandler}
              cardMenu={(i) => renderOptions(i)}
            />
          )
        }
      </div>

      <Toast
        count={selectedItems.length}
        itemTypeText={selectedItems.length > 1 ? 'scenarios' : 'scenario'}
        closeHandler={() => {
          dismissToastHandler()
          setTimeout(() => {
            setSelectedItems([])
          }, 10)
        }}
        dismissable
        isClosing={dismissToast}>
        <>
          <button
            title={`Duplicate ${
              selectedItems.length > 1 ? 'scenarios' : 'scenario'
            }`}
            onClick={() => {
              getClientsForDuplication()
              setCurrentScenarios(
                data.scenarios.filter((i) => selectedItems.includes(i.id))
              )
              $('#duplicateScenarioModal').foundation('open')
            }}>
            <FaCopy />
          </button>
          <button
            className="danger"
            title={`Delete ${
              selectedItems.length > 1 ? 'scenarios' : 'scenario'
            }`}
            onClick={() => {
              setCurrentScenarios(
                selectedItems.map((id) =>
                  data.scenarios.find((i) => i.id === id)
                )
              )
              $('#deleteModal').foundation('open')
            }}>
            <FaTrash />
          </button>
        </>
      </Toast>
    </div>
  )
}

export default withApollo(Scenarios)
