import React from 'react'
import { getLetterFromNumber } from '../../../helpers/elementHelper'

const ElementLetterLabel = ({ index, letter, className = '' }) => {
  return (
    <span
      className={`o-label o-label--stable-lighter text-stable-dark text-small ${className}`}>
      {letter ?? getLetterFromNumber(index)}
    </span>
  )
}

export default ElementLetterLabel
