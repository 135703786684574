import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../../UI/Modal'
import { FaArrowLeft, FaTimes } from 'react-icons/fa'
import { ReactSVG } from 'react-svg'
import { convertSize } from '../../../../utils/format'
import icon360 from '../../../../../../images/icon-360.svg'
import icon180 from '../../../../../../images/icon-180.svg'
import iconFlat from '../../../../../../images/icon-flat-media.svg'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import { getAcceptedExtensions } from '../../../../utils/helpers/files'

const MEDIUM_KINDS = [
  {
    id: '360',
    name: '360° media',
    subtitle: 'Video',
    icon: icon360,
  },
  {
    id: '180',
    name: '180° media',
    subtitle: 'Video',
    icon: icon180,
  },
  {
    id: 'flat',
    name: 'Flat media',
    subtitle: 'Video or image',
    icon: iconFlat,
  },
]

const ChooseKind = ({ setKind, currentClient }) => {
  const [hover, setHover] = useState()
  return (
    <>
      <label>What type of media file do you like to upload?</label>
      <div className="flex-container pb-1">
        {MEDIUM_KINDS.map((medium, index) => {
          if (
            !currentClient.features.includes('VIDEO_180') &&
            medium.id === '180'
          )
            return null
          if (
            currentClient.features.includes('IMAGE_3D') &&
            medium.id === '360'
          )
            medium.subtitle = 'Video or image'
          return (
            <button
              key={medium.id}
              className={`w-100 h-100 border border-radius mt-1 p-2 flex-container flex-dir-column align-middle
              cursor-pointer button hollow
              ${hover === medium.id ? 'secondary active' : 'dark'}
              ${index < MEDIUM_KINDS.length - 1 ? 'mr-1' : ''}
            `}
              onClick={() => setKind(medium.id)}
              onMouseEnter={() => setHover(medium.id)}
              onMouseLeave={() => setHover('')}>
              <ReactSVG
                src={medium.icon}
                className="mb-2"
                style={{ height: '55px' }}
              />
              <label className="cursor-pointer">{medium.name}</label>
              <p className="text-stable-dark mb-0">{medium.subtitle}</p>
            </button>
          )
        })}
      </div>
    </>
  )
}

const MediaUploadModal = ({ id, appendTo, uploadFilesHandler, legacyFlow }) => {
  const [kind, setKind] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [chosedFiles, setChosedFiles] = useState([])
  const [, currentClient] = useCurrentUser()
  const [noFeatureFlag, setNoFeatureFlag] = useState()

  useEffect(() => {
    if (legacyFlow) {
      setNoFeatureFlag(true)
      setKind('360')
    }
  }, [legacyFlow])

  if (!currentClient) return null

  const renderBackBanner = () => {
    if (!kind || noFeatureFlag) return
    return (
      <div className="pt-2 pb-2 pl-3 mb-3 border-bottom flex-container align-middle">
        <button
          className="button hollow secondary mb-0 mr-2"
          onClick={() => setKind('')}>
          <FaArrowLeft className="mr-1" />
          Back
        </button>
        <label>Upload {MEDIUM_KINDS.find((m) => m.id === kind).name}</label>
      </div>
    )
  }

  const handleDragEnter = () => setIsDragging(true)
  const handleDragLeave = () => setIsDragging(false)
  const handleDrop = () => setIsDragging(false)

  const getExtensions = () => {
    if (noFeatureFlag) return '.mp4'

    return getAcceptedExtensions(kind)
  }

  const getFileWrapper = () => {
    if (!kind) return

    const renderText = () => {
      if (isDragging)
        return <label className="text-assertive">Drop files here</label>
      return (
        <>
          Drag and drop files here or &nbsp;
          <span className="text-underline text-dark">Choose file</span>
        </>
      )
    }

    return (
      <>
        <label>Upload files here</label>
        <div
          className={`border-2 border-radius border-dashed flex-container align-center align-middle mb-2
            cursor-pointer text-normal text-stable-dark o-file-input--basic
            ${isDragging ? 'bg-assertive-light border-assertive' : 'border-stable'}
          `}
          style={{
            minHeight: '200px',
            position: 'relative',
          }}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}>
          {renderText()}
          <input
            type="file"
            multiple
            id="upload-media"
            accept={getExtensions()}
            onChange={(e) => {
              // this validation is useful when user drop files
              const validExtensions = getExtensions()
                .split(', ')
                .map((ext) => ext.trim())
              const validFiles = Array.from(e.target.files).filter((file) =>
                validExtensions.includes(
                  `.${file.name.toLowerCase().split('.').pop()}`
                )
              )
              setChosedFiles([...chosedFiles, ...validFiles])
            }}
          />
        </div>
      </>
    )
  }

  const getMediaList = () => {
    if (chosedFiles.length === 0) return
    return (
      <div className="border border-radius text-normal mb-2">
        {chosedFiles.map((file, index) => (
          <div
            key={file.name}
            className={`p-1-5 flex-container align-middle align-justify
             ${index < chosedFiles.length - 1 ? 'border-bottom' : ''}`}>
            <span
              className="text-truncate"
              style={{ maxWidth: 'calc(100% - 80px)' }}>
              {file.name}
            </span>
            <div>
              <span className="text-stable-dark">{convertSize(file.size)}</span>
              <button
                className="text-stable-dark ml-2 cursor-pointer"
                onClick={() => {
                  setChosedFiles(
                    chosedFiles.filter((f) => file.name !== f.name)
                  )
                }}>
                <FaTimes />
              </button>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderSubtitle = () => {
    if (noFeatureFlag) return 'Upload 360° media files.'

    let subtitle = 'Upload 360°'
    if (currentClient.features.includes('VIDEO_180')) subtitle += ', 180°'
    subtitle += ' or flat media files.'

    return subtitle
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      onOpen={() => {
        setChosedFiles([])
        if (!noFeatureFlag) setKind('')
      }}
      headerText="Upload media file"
      buttons={
        kind ? (
          <>
            <button className="hollow button secondary" data-close={id}>
              Cancel
            </button>
            <button
              type="submit"
              className="button primary"
              data-close={id}
              disabled={chosedFiles.length === 0}
              onClick={() => uploadFilesHandler(chosedFiles, kind)}>
              Confirm
            </button>
          </>
        ) : null
      }
      subtitle={
        <>
          {renderSubtitle()}
          &nbsp;Support for both mono- and stereoscopic videos.&nbsp;
          <a
            href="https://help.warpvr.com/en/article/upload-media-146c57/"
            target="_blank"
            className="text-stable-dark text-underline">
            More about all supported file types here
          </a>
        </>
      }
      banner={renderBackBanner()}>
      <form>
        {!kind && (
          <ChooseKind setKind={setKind} currentClient={currentClient} />
        )}
        {getFileWrapper()}
        {getMediaList()}
      </form>
    </Modal>
  )
}
export default MediaUploadModal
