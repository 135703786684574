import React, { useState } from 'react'
import DataTooltip from '../../UI/DataTooltip'

const MediaSceneTooltip = ({ scenes }) => {
  const [isHovered, setIsHovered] = useState(false)
  if (!scenes.length) return '-'

  const listItemName = (name, number) =>
    `<li style="text-align:start; list-style:none ">
      <strong>${number}</strong> | ${name}</li>`

  if (isHovered) {
    const uniqueScenes = scenes.filter(
      (scene, index, self) => index === self.findIndex((s) => s.id === scene.id)
    )

    const title = `
      <div data-testid='tooltip' style="padding:10px;">
      <span className="text-white" style="text-align: start; font-weight:700;">Linked to ${
        uniqueScenes.length
      } scene${uniqueScenes.length === 1 ? '' : 's'}</span>
      <ul data-testid='scenes' style="list-style:none;margin-right:10px; margin-bottom: 0px; margin-top: 10px">
      ${[...uniqueScenes]
        .sort((a, b) => a.number - b.number)
        .map((scene) => listItemName(scene.name, scene.number))
        .join('')}
      </ul>
      </div>
    `
    return (
      <DataTooltip title={title}>
        <span className="text-underline cursor-default">
          {scenes.length} scene{scenes.length === 1 ? '' : 's'}
        </span>
      </DataTooltip>
    )
  }
  return (
    <span
      data-testid="hover-button"
      className="text-underline cursor-default"
      onMouseEnter={() => setIsHovered(true)}>
      {scenes.length} scene{scenes.length === 1 ? '' : 's'}
    </span>
  )
}
export default MediaSceneTooltip
