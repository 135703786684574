import React from 'react'
import {
  FaAlignLeft,
  FaArrowUp,
  FaBars,
  FaComment,
  FaCommentAlt,
  FaDotCircle,
  FaImage,
  FaInfo,
  FaMinus,
  FaRandom,
  FaRedo,
  FaStopwatch,
} from 'react-icons/fa'
import { FaClapperboard } from 'react-icons/fa6'
import { htmlToText } from '../../../utils/helpers/javascript'

export const ELEMENT_TYPES = [
  {
    type: 'MPC',
    title: 'Multiple choice',
    icon: <FaBars />,
    isUserAddable: true,
    deleteDisabledText: 'A multiple choice element needs at least one answer',
  },
  {
    type: 'INFORMATION',
    title: 'Information',
    icon: <FaInfo />,
    isUserAddable: true,
  },
  {
    type: 'DIRECTION',
    title: 'Direction',
    icon: <FaArrowUp />,
    isUserAddable: true,
  },
  {
    type: 'HOTSPOT',
    title: 'Hotspot',
    icon: <FaDotCircle />,
    isUserAddable: true,
  },
  {
    type: 'BUTTON',
    title: 'Button',
    icon: <FaMinus />,
    isUserAddable: true,
  },
  {
    type: 'QUESTION',
    title: 'Question',
    icon: <FaCommentAlt />,
    isUserAddable: false,
  },
  {
    type: 'ANSWER',
    title: 'Answer',
    icon: <FaMinus />,
    isUserAddable: false,
  },
  {
    type: 'TIMER',
    title: 'Timer',
    icon: <FaStopwatch />,
    isUserAddable: true,
  },
  {
    type: 'TRANSITION',
    title: 'Auto-transition',
    icon: <FaRedo />,
    isUserAddable: true,
  },
  {
    type: 'TOOLTIP',
    title: 'Tooltip',
    icon: <FaComment />,
    isUserAddable: true,
  },
  {
    type: 'RANDOMIZER',
    title: 'Randomizer',
    icon: <FaRandom />,
    isUserAddable: false,
  },
  {
    type: 'MEDIAPANEL',
    title: 'Media panel',
    icon: <FaImage />,
    isUserAddable: true,
    deleteDisabledText: 'A media panel element needs at least one slide',
  },
  {
    type: 'SLIDE',
    title: 'Slide',
    icon: null,
    isUserAddable: false,
  },
]

export const INTERACTIVE_ELEMENT_TYPES = [
  'INFORMATION',
  'MPC',
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TIMER',
  'TRANSITION',
]

export const GROUP_ELEMENT_TYPES_HAS_ORDER = ['SLIDE']

export const GROUP_ELEMENT_TYPES = [
  { type: 'INFORMATION', subEls: ['TOOLTIP', 'BUTTON'] },
  { type: 'MPC', subEls: ['QUESTION', 'ANSWER'] },
  { type: 'MEDIAPANEL', subEls: ['TOOLTIP', 'SLIDE'] },
]

export const GROUP_PARENT_ELEMENT_TYPES = ['QUESTION', 'TOOLTIP']
export const GROUP_CHILD_ELEMENT_TYPES = ['ANSWER', 'BUTTON', 'SLIDE']
export const VISIBLE_NODE_CARD_CHILD_ELEMENT_TYPES = [
  'ANSWER',
  'BUTTON',
  'SLIDE',
]
export const AUTO_ADD_CHILD_ELEMENT_TYPES = [
  'QUESTION',
  'ANSWER',
  'BUTTON',
  'TOOLTIP',
  'SLIDE',
]

export const VIDEO_ELEMENT_TYPES = [
  'DIRECTION',
  'HOTSPOT',
  'BUTTON',
  'ANSWER',
  'TOOLTIP',
  'SLIDE',
]

export const MEDIA_SLIDE_TYPES = ['TEXT', 'IMAGE', 'VIDEO']

export const getLetterFromNumber = (number) => {
  let result = ''
  while (number > 0) {
    let remainder = (number - 1) % 26
    result = String.fromCharCode(65 + remainder) + result
    number = Math.floor((number - 1) / 26)
  }
  return result
}

export const getPointClass = (points, scoringSystem) => {
  const classes = [
    'stable-dark',
    'royal',
    scoringSystem === 'SUBTRACT' ? 'assertive' : 'energized',
  ]
  if (scoringSystem === 'DISABLED') return classes[0]
  if (points > 2) return 'balanced'

  return classes[points ?? 0]
}

export const getPointColor = (points, scoringSystem) => {
  const colors = [
    '#93969f',
    '#e8c60a',
    scoringSystem === 'SUBTRACT' ? '#e7461a' : '#70c8b8',
  ]
  if (scoringSystem === 'DISABLED') return colors[0]
  if (points > 2) return '#548ab9'
  return colors[points ?? 0]
}

export const getElementFromSourceHandle = (reactFlow, nodeId, handleId) =>
  reactFlow
    .getNode(nodeId)
    .data.elements?.find((e) => e.id === handleId.split('-')?.[1])

export const getConnectedElementsToScene = (scenes, sceneId) => {
  return scenes
    .map((scene) => scene.elements)
    .flat()
    .filter(
      (el) =>
        el.linkToId === sceneId || el.randomizedLinkToIds?.includes(sceneId)
    )
}

export const getDisplayedNodeElements = (
  nodeData,
  filterUnvisibleNodeElements
) => {
  let nextParentIndex = 0
  if (!nodeData?.elements) return []

  const allAnswerElements = nodeData.elements.filter(
    (el) => GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) && el.groupUuid
  )

  let elements = nodeData.elements
    .filter((el) => !allAnswerElements.find((aEl) => aEl.id === el.id))
    .sort((a, b) => a.id - b.id)

  return elements.map((el) => {
    let answerElements = allAnswerElements.filter(
      (a) => a.groupUuid === el.groupUuid
    )
    const elementHasLetter =
      INTERACTIVE_ELEMENT_TYPES.includes(el.kind) ||
      (INTERACTIVE_ELEMENT_TYPES.includes(el.groupKind) &&
        answerElements.length)

    const parentIndex = nextParentIndex
    if (elementHasLetter)
      nextParentIndex +=
        1 + (answerElements.length ? answerElements.length - 1 : 0)

    answerElements = answerElements
      .map((ae, childIndex) => ({
        ...ae,
        letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(ae.kind)
          ? getLetterFromNumber(parentIndex + (childIndex ?? 0) + 1)
          : '',
      }))
      .sort((a, b) =>
        a.number === -1 && b.number === -1 ? a.id - b.id : a.number - b.number
      )

    if (filterUnvisibleNodeElements) {
      answerElements = answerElements.filter((el) =>
        VISIBLE_NODE_CARD_CHILD_ELEMENT_TYPES.includes(el.kind)
      )
    }

    return {
      ...el,
      index: parentIndex,
      letterLabel: INTERACTIVE_ELEMENT_TYPES.includes(el.kind)
        ? getLetterFromNumber(parentIndex + 1)
        : '',
      answerElements,
    }
  })
}

export const selectSidebarElement = (id) => {
  const sidebarCard = document.querySelector(`#${id}`)
  if (sidebarCard) sidebarCard.classList.add('active')
}

export const unSelectSidebarElements = () => {
  ;[
    ...document.querySelectorAll('.c-video__editor--elements--card.active'),
  ].forEach((el) => el.classList.remove('active'))
}

export const getLatestNumber = (node, { groupUuid, kind }) => {
  if (!node) return 0

  const elements = node.data.elements

  const getLastElementNumber = (filteredElements) => {
    const lastElement = filteredElements.slice(-1)[0]
    return lastElement?.number && lastElement.number !== -1
      ? lastElement.number
      : GROUP_ELEMENT_TYPES_HAS_ORDER.includes(kind)
        ? 1000
        : 0
  }

  if (groupUuid) {
    const groupElements = elements.filter(
      (el) =>
        el.groupUuid === groupUuid &&
        GROUP_CHILD_ELEMENT_TYPES.includes(el.kind)
    )
    return getLastElementNumber(groupElements)
  }

  const interactiveElements = elements.filter((el) =>
    INTERACTIVE_ELEMENT_TYPES.includes(el.kind)
  )
  return getLastElementNumber(interactiveElements)
}

export const fillElementDefaultValues = (element) => ({
  ...element,
  showInLookDirection: element.showInLookDirection ?? false,
  label: htmlToText(element.label) ?? '',
})

export const getElementSlideType = (element) => {
  if (!element.description && !element.flatAttachment && !element._removeDraft)
    return 'DRAFT'

  if (!element.flatAttachment) return 'TEXT'
  if (element.flatAttachment.kind === 'VIDEO') return 'VIDEO'
  return 'IMAGE'
}

export const getSlideTypeIcon = (slideType) => {
  if (['TEXT', 'DRAFT'].includes(slideType)) return <FaAlignLeft />
  if (slideType === 'VIDEO') return <FaClapperboard />
  return <FaImage />
}

export const getElementSlideTypeIcon = (element) => {
  return getSlideTypeIcon(getElementSlideType(element))
}

export const getElementRow = (element) => {
  const elRow = ELEMENT_TYPES.find((el) => el.type === element.kind)
  if (elRow.type === 'SLIDE')
    return {
      ...elRow,
      icon: getElementSlideTypeIcon(element),
    }

  return elRow
}

export const setSlidesData = (slides) => {
  const slidesModal = document.querySelector('#slides-modal')
  if(slidesModal) {
    const event = new CustomEvent('setSlidesData', {
      detail: { slides },
    })
    slidesModal.dispatchEvent(event)
  }
}
