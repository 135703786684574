import { useCurrentEditor } from '@tiptap/react'
import React, { Fragment } from 'react'
import { FaBold, FaItalic, FaUnderline } from 'react-icons/fa'

const PLUGINS = ['bold', 'italic', 'underline']

const ToolbarPlugin = () => {
  const { editor } = useCurrentEditor()

  const renderPlugin = (plugin) => {
    switch (plugin) {
      case 'bold':
        return (
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`button hollow o-button--light secondary o-button--square-small ${editor.isActive('bold') ? 'active' : ''}`}>
            <FaBold className="format bold" />
          </button>
        )
      case 'italic':
        return (
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={`button hollow o-button--light secondary o-button--square-small ${editor.isActive('italic') ? 'active' : ''}`}>
            <FaItalic className="format italic" />
          </button>
        )
      case 'underline':
        return (
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={`button hollow o-button--light secondary o-button--square-small ${editor.isActive('underline') ? 'active' : ''}`}>
            <FaUnderline className="format underline" />
          </button>
        )
      default:
        return null
    }
  }

  return (
    <div
      className="shrink button-group o-button-group no-gaps"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      {PLUGINS.map((p) => (
        <Fragment key={p}>{renderPlugin(p)}</Fragment>
      ))}
    </div>
  )
}

export default ToolbarPlugin
