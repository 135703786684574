import React, { useContext } from 'react'
import { MediumContext } from '../MediumContext'
import LoadingSpinner from '../../../UI/LoadingSpinner'
import { default as S } from '../../../../utils/lang/en.json'
import { capitalized } from '../../../../utils/helpers/javascript'
import Delay from '../../../../utils/helpers/Delay'

const MediumVideoPlayer = () => {
  const {
    medium: [medium],
    currentVideoRef,
    controls: [controls, setControls],
    time: [, setTime],
  } = useContext(MediumContext)

  const loadingElement = (text) => (
    <div
      data-testid="loading"
      key={'loading-medium-data'}
      style={{
        aspectRatio: '2/1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}>
      <Delay timeout="2000">
        <LoadingSpinner noHeight text={text} />
      </Delay>
    </div>
  )

  if (!medium) return loadingElement()
  if (medium.state !== 'COMPLETED') {
    return loadingElement(
      capitalized(S.dataTypes.transform.state[medium.state])
    )
  }

  return (
    <div
      data-testid="video-wrapper"
      id="video-wrapper"
      className="bg-dark"
      style={{
        aspectRatio: '2/1',
        display: 'flex',
        justifyContent: 'center',
      }}>
      <video
        data-testid="video"
        id={`video-${medium.id}`}
        style={{ maxWidth: '100%' }}
        ref={currentVideoRef}
        src={medium.playUrl}
        crossOrigin="anonymous"
        onTimeUpdate={(e) => {
          const el = e.currentTarget
          setTime(el.currentTime * 1000)
          setControls({ ...controls, isPlaying: !el.paused && !el.ended })
        }}
      />
    </div>
  )
}
export default MediumVideoPlayer
