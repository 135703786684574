import React from 'react'
import { useLocation } from 'react-router'
import FlowEditor from '../tabs/FlowEditor'
const Filming = React.lazy(() => import('../tabs/Filming'))
const Media = React.lazy(() => import('../tabs/Media'))
const Settings = React.lazy(() => import('../tabs/Settings'))

export const EDITOR_TABS = [
  { title: 'Flow', key: '', content: <FlowEditor />, isRoute: false },
  { title: 'Filming', key: 'filming', content: <Filming />, isRoute: true },
  { title: 'Media', key: 'media', content: <Media />, isRoute: true },
  { title: 'Settings', key: 'settings', content: <Settings />, isRoute: true },
]

export const useEditorActiveTab = () => {
  const location = useLocation()
  const activeTab = location.pathname.substring(1)

  return (
    EDITOR_TABS.filter((tab) => tab.key).find((tab) =>
      activeTab.includes(tab.key)
    ) ?? EDITOR_TABS.find((tab) => tab.key === '')
  )
}
