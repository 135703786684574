import React, { useEffect, useRef, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'
import sanitizeHTML from '../../../utils/helpers/sanitizeHTML'
import Modal from '../../UI/Modal'
import { getElementSlideType } from '../../FlowV2/helpers/elementHelper'
import usePrevious from '../../../hooks/usePrevious'

const SLIDE_WIDTH = 640
const SLIDE_HEIGHT = 360

const OverlayTitle = ({ slide }) => {
  if (!slide.label) return null
  return (
    <>
      <div
        className="absolute w-100"
        style={{
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
          height: '82px',
          opacity: 0.6,
        }}
      />
      <h2 className="text-bold text-xxlarge text-white absolute w-100 p-3">
        {slide.label}
      </h2>
    </>
  )
}

const Video = ({ element, index, activeSlide, hasOpened }) => {
  const videoRef = useRef()
  const controlRef = useRef()
  const [canPlay, setCanPlay] = useState(false)

  const onCanPlay = () => setCanPlay(true)

  const onTimeUpdate = () => {
    const control = controlRef.current
    const video = videoRef.current
    const width = (video.currentTime / video.duration) * 100
    control.style.width = `${width}%`
    return true
  }

  useEffect(() => {
    if (activeSlide === index && canPlay && hasOpened) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
      videoRef.current.currentTime = 0
      controlRef.current.style.width = '0%'
    }
  }, [activeSlide, index, canPlay, hasOpened])

  return (
    <div className="relative bg-dark border-radius aspect-16/9 h-100">
      <OverlayTitle slide={element} />
      <video
        ref={videoRef}
        src={element?.flatAttachment?.url}
        className="object-contain h-100 block"
        loading="lazy"
        loop={true}
        onCanPlay={onCanPlay}
        crossOrigin="anonymous"
        onTimeUpdate={onTimeUpdate}
      />
      <div
        className="absolute w-100"
        style={{ maxWidth: 'calc(100% - 60px)', bottom: '30px', left: '30px' }}>
        <div
          className="bg-light"
          ref={controlRef}
          style={{ height: '4px', opacity: 0.5, transition: 'all .3s linear' }}
        />
      </div>
    </div>
  )
}

const SlidesModal = () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [slides, setSlides] = useState([])
  const prevSlides = usePrevious(slides)
  const [hasOpened, setHasOpened] = useState(false)

  const renderSlide = (slide, index) => {
    const type = getElementSlideType(slide)

    const types = {
      TEXT: (
        <div className="bg-light p-3 h-100">
          <h2 className="text-bold mb-2 text-xxlarge">{slide.label}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(slide.description),
            }}
            className="overflow-hidden"
            style={{ maxHeight: '263px' }}
          />
        </div>
      ),
      IMAGE: (
        <div
          className="relative bg-dark border-radius h-100"
          style={{ aspectRatio: '16/9' }}>
          <OverlayTitle slide={slide} />
          <img
            src={slide?.flatAttachment?.url}
            className="object-contain w-100 h-100 block"
            loading="lazy"
          />
        </div>
      ),
      VIDEO: (
        <Video
          element={slide}
          index={index}
          activeSlide={activeSlide}
          hasOpened={hasOpened}
        />
      ),
    }

    return types[type]
  }

  useEffect(() => {
    document
      .querySelector('#slides-modal')
      .addEventListener(
        'setSlidesData',
        ({ detail: { slides: newSlides } }) => {
          if (newSlides.length > 0) {
            newSlides = newSlides.filter(
              (slide) => getElementSlideType(slide) !== 'DRAFT'
            )
            setSlides(newSlides)
          }
        }
      )
  }, [])

  useEffect(() => {
    if (prevSlides?.length !== slides?.length) setActiveSlide(0)
  }, [slides, prevSlides])

  return (
    <Modal
      id="slides-modal"
      appendTo="#videoplayer"
      width={SLIDE_WIDTH}
      closable={false}
      verticalAllign="center"
      onOpen={() => {
        setHasOpened(true)
        const video = document.querySelector('#video')
        if (!video.paused) {
          video.pause()
          video.setAttribute('data-stop-by', 'slides-modal')
        }
      }}
      onClose={() => {
        setHasOpened(false)
        const video = document.querySelector('#video')
        if (video.getAttribute('data-stop-by') === 'slides-modal') video.play()
      }}>
      <div
        className="relative flex-container align-center"
        style={{
          height: `${SLIDE_HEIGHT}px`,
        }}>
        <div
          className={`left flex-container align-middle h-100 ${activeSlide === 0 ? 'invisible' : ''}`}>
          <div
            className="border-radius--rounded-full bg-light cursor-pointer flex-container align-middle align-center mr-3"
            style={{ width: '42px', height: '42px' }}
            onClick={() => setActiveSlide(activeSlide - 1)}>
            <FaChevronLeft />
          </div>
        </div>

        <div className="relative">
          <div
            className="absolute border-radius--rounded-full bg-light cursor-pointer flex-container align-middle align-center"
            style={{ right: '-72px', top: 0, width: '42px', height: '42px' }}
            onClick={() => $('#slides-modal').foundation('close')}>
            <FaTimes />
          </div>

          <div
            className="overflow-hidden border-radius"
            style={{
              width: `${SLIDE_WIDTH}px`,
              height: `${SLIDE_HEIGHT}px`,
            }}>
            <div
              className="flex-container h-100"
              style={{
                width: `${SLIDE_WIDTH * slides.length}px`,
                transform: `translateX(-${activeSlide * SLIDE_WIDTH}px)`,
                transition: 'transform 0.3s',
              }}>
              {slides.map((slide, index) => (
                <div
                  key={slide.id}
                  className="slide overflow-hidden"
                  style={{
                    minWidth: `${SLIDE_WIDTH}px`,
                    width: `${SLIDE_WIDTH}px`,
                    height: `${SLIDE_HEIGHT}px`,
                  }}>
                  {renderSlide(slide, index)}
                </div>
              ))}
            </div>
          </div>
          {slides.length > 0 && (
            <div
              id="pagination"
              className="flex-container align-center w-100 pt-1">
              {slides.map((slide, index) => (
                <div
                  key={slide.id}
                  onClick={() => setActiveSlide(index)}
                  className="bg-light border-radius cursor-pointer"
                  style={{
                    transition: 'width 0.3s',
                    height: '8px',
                    width: index === activeSlide ? '16px' : '8px',
                    margin: '0 4px',
                  }}
                />
              ))}
            </div>
          )}
        </div>

        <div
          className={`right flex-container align-middle h-100 ${activeSlide === slides.length - 1 ? 'invisible' : ''}`}>
          <div
            className="border-radius--rounded-full bg-light cursor-pointer flex-container align-middle align-center ml-3"
            style={{ width: '42px', height: '42px' }}
            onClick={() => setActiveSlide(activeSlide + 1)}>
            <FaChevronRight />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SlidesModal
