import { PropTypes } from 'prop-types'
import React, { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import useFoundation from '../../hooks/useFoundation'

const Modal = ({
  id,
  appendTo,
  headerText,
  subtitle,
  banner,
  buttons,
  useDefaultButtons,
  submitHandler,
  cancelHandler,
  submitButtonText,
  submitDisabled = false,
  footerText,
  width = 600,
  closable = true,
  closeOnEscape = true,
  overflowY,
  textAssertive,
  children,
  hOffset,
  vOffset,
  onClose,
  onOpen,
  useFooterBorder,
  verticalAllign = 'top',
}) => {
  const ref = useFoundation()
  useEffect(() => {
    if (onClose) $(`#${id}`).on('closed.zf.reveal', onClose)
    if (onOpen) $(`#${id}`).on('open.zf.reveal', onOpen)
    return () => {
      if (onClose) $(`#${id}`).off('closed.zf.reveal', onClose)
      if (onOpen) $(`#${id}`).off('open.zf.reveal', onOpen)
    }
  }, [children])

  const defaultButtons = (
    <>
      <button
        className="hollow button secondary wide"
        data-close={id}
        onClick={cancelHandler}>
        Cancel
      </button>
      <button
        type="submit"
        className="button primary wide"
        data-close={id}
        disabled={submitDisabled}
        onClick={submitHandler}>
        {submitButtonText || 'Confirm'}
      </button>
    </>
  )

  if (!headerText && !subtitle && !useDefaultButtons && !buttons) {
    return (
      <div
        ref={ref}
        className={`reveal o-modal--custom text-left ${
          closable ? 'o-modal--closable' : ''
        } ${verticalAllign === 'top' ? 'o-modal--top' : ''}`}
        data-animation-in="fade-in"
        data-animation-out="fade-out"
        data-close-on-click={false}
        data-close-on-esc={closeOnEscape}
        id={id}
        data-append-to={appendTo}
        data-reveal
        data-v-offset={vOffset}
        data-h-offset={hOffset}
        style={{
          ...(overflowY ? { overflowY } : {}),
          width: Number(width) ? `${width}px` : width,
        }}>
        {closable && (
          <span className="o-modal--close-button" data-close={id}>
            <FaTimes />
          </span>
        )}
        {children}
      </div>
    )
  }
  return (
    <div
      ref={ref}
      className={`reveal text-left ${closable ? 'o-modal--closable' : ''}`}
      id={id}
      data-append-to={appendTo}
      data-close-on-click={false}
      data-close-on-esc={closeOnEscape}
      data-animation-out="fade-out"
      style={{
        ...(overflowY ? { overflowY } : {}),
        width: width.toString().endsWith('px') ? `${width}px` : width,
      }}
      data-reveal
      data-v-offset={vOffset}
      data-h-offset={hOffset}>
      {closable && (
        <span
          className="o-modal--close-button"
          onClick={cancelHandler}
          data-close={id}>
          <FaTimes />
        </span>
      )}
      {headerText && (
        <div className="o-modal--header border-bottom">
          <h2
            className={`text-bold mb-0  ${
              textAssertive ? 'text-assertive' : ''
            }`}>
            {headerText}
          </h2>
          {subtitle && (
            <div className="small p-1 pt-0 o-modal--subtitle">{subtitle}</div>
          )}
        </div>
      )}
      {banner && <div className="o-modal--banner">{banner}</div>}
      {children && <div className="o-modal--content">{children}</div>}
      {(footerText || useDefaultButtons || buttons) && (
        <div
          className={`clearfix o-modal--footer ${
            useFooterBorder ? 'border-top' : ''
          }`}>
          {footerText && <p className=" o-modal--footer--text">{footerText}</p>}
          {useDefaultButtons && defaultButtons}
          {buttons}
        </div>
      )}
    </div>
  )
}
export default Modal

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  appendTo: PropTypes.string.isRequired,
}
