import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaChevronDown, FaPlus } from 'react-icons/fa'
import SearchableDropdown from '../../../UI/Menu/SearchableDropdown'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { handleApolloError } from '../../../../utils/errors'
import { addCameraLocationMutation } from '../../../../apollo/query/cameraLocations'
import { useMutation } from '@apollo/client'
import { updateSceneFilmingMutation } from '../../../../apollo/query/scenes'
import { setNode } from '../../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'
import useSelectedNode from '../../hooks/useSelectedNode'

const FilmingLocationsDropdown = () => {
  let timer
  const selectedNode = useSelectedNode()
  const scene = selectedNode.data
  const { scenario, refetch } = useContext(ScenarioEditorContext)
  const [searchKey, setSearchKey] = useState('')
  const [show, setShow] = useState(false)
  const reactFlow = useReactFlow()

  const [addCameraLocation] = useMutation(addCameraLocationMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const [updateSceneFilming] = useMutation(updateSceneFilmingMutation, {
    onError: handleApolloError,
    onCompleted: refetch,
  })

  const handleChangeElement = (selectedItem) => {
    const newData = {
      cameraLocationId: selectedItem.id,
      useSameVideoAsId: null,
      filming: scene.filming,
    }
    const newNode = {
      ...selectedNode,
      data: {
        ...scene,
        ...newData,
      },
    }
    setNode(reactFlow, newNode)

    updateSceneFilming({
      variables: {
        id: scene.id,
        ...newData,
      },
    })
  }

  const getFooter = () => {
    if (!searchKey) return null

    return (
      <div
        className="flex-container align-middle padding-vertical-1 text-stable-dark"
        onClick={() => {
          addCameraLocation({
            variables: {
              scenarioId: scenario.id,
              name: searchKey,
              description: '',
            },
          }).then(() => {
            setSearchKey('')
          })
        }}>
        <FaPlus className="mr-1" />
        <div>
          <span className="text-bold">New location with the name&nbsp;</span>
          <span className="text-dark">{searchKey}</span>
        </div>
      </div>
    )
  }

  const showSearchDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => setShow(true), 500)
  }

  const hideDropdown = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setShow(false)
    }, 500)
  }

  const cameraLocations = useMemo(() => {
    return scenario.cameraLocations.map((c) => ({
      ...c,
      value: c.name,
      render: c.name,
    }))
  }, [scenario.cameraLocations])

  useEffect(() => {
    document.querySelector('#flow-container > aside').style.overflow = show
      ? 'visible'
      : 'auto'
  }, [show])

  return (
    <div className="pl-2 pr-2 pb-1-5 pt-1-5 flex-container align-middle align-justify">
      <span>Location</span>
      <div
        style={{ position: 'relative', width: '50%' }}
        onMouseEnter={showSearchDropdown}
        onMouseLeave={hideDropdown}>
        <button
          className="o-button o-button--square-small o-button--light flex-container align-middle mb-0 pl-1 pr-1 w-100 text-normal"
          style={{ justifyContent: 'space-between' }}
          onClick={() => setShow(!show)}>
          <span className="text-bold">
            {scene?.cameraLocation?.name ?? 'Select...'}
          </span>
          <FaChevronDown className="ml-1" />
        </button>
        {show && (
          <SearchableDropdown
            placeholder={'Search or create a filming location'}
            clickHandler={(cameraLocation) => {
              setShow(false)
              handleChangeElement(cameraLocation)
            }}
            hideHandler={() => setShow(false)}
            notFoundTitle={'No filming locations found'}
            notFoundText={
              <small>You don't have any filming locations to add</small>
            }
            data={cameraLocations}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            footer={getFooter()}
            show={show}
            position="right"
          />
        )}
      </div>
    </div>
  )
}

export default FilmingLocationsDropdown
