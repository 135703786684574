const getBlobfromDataURL = (dataUrl, type = 'image/png') => {
  const binary = atob(dataUrl.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type })
}

const getFileFromBlob = (blob, name, type) => {
  return new File([blob], name, { type, lastModified: new Date() })
}

const getAcceptedExtensions = (kind) => {
  if (!kind) return

  switch (kind) {
    case '360':
    case 'flat':
    case 'FLAT_VIDEO':
    case 'FLAT_IMAGE':
      return '.mp4, .jpg, .png'
    case 'VIDEO_360':
    case 'VIDEO_180':
    default:
      return '.mp4'
  }
}

export { getBlobfromDataURL, getFileFromBlob, getAcceptedExtensions }
