import React, { useContext } from 'react'
import { FaPause, FaPlay } from 'react-icons/fa'
import { MediumContext } from '../../Media/Modal/MediumContext'
import VolumeSlider from '../../Media/Modal/partials/Controls/VolumeSlider'
import TimeIndicator from '../../Media/Modal/partials/TimeIndicator'
import EditorTimeSlider from './EditorTimeSlider'
import useSelectedNode from '../../FlowV2/hooks/useSelectedNode'
import { default as S } from '../../../utils/lang/en.json'

const EditorControlsRow = () => {
  const {
    medium: [medium],
    controls: [controls, setControls],
    currentVideoRef,
  } = useContext(MediumContext)

  const selectedNode = useSelectedNode()

  if (!selectedNode || !medium) return null
  const { video } = selectedNode.data

  const pauseHandler = () => {
    currentVideoRef.current.removeAttribute('data-stop-by')

    if (!currentVideoRef.current.paused) {
      currentVideoRef.current.pause()
      setControls({ ...controls, isPlaying: false })
    } else {
      currentVideoRef.current.play()
      setControls({ ...controls, isPlaying: true })
      $('#slides-modal').foundation('close')
    }
  }
  return (
    <div data-testid="editor-controls-row" key={video.id}>
      <div className="mb-1 mt-1 grid-x c-video__editor--slider-row border-none">
        <div className="cell small-3 large-2 grid-x">
          <button
            data-testid="play-button"
            style={{
              minWidth: '46px',
              maxWidth: '80px',
            }}
            className="button cell auto secondary hollow mb-0 mr-1"
            onClick={pauseHandler}>
            {controls.isPlaying ? (
              <FaPause className="text-dark" data-testid="is-playing" />
            ) : (
              <FaPlay className="text-dark" data-testid="is-paused" />
            )}
          </button>
          <VolumeSlider />
        </div>
        <TimeIndicator editorControls />
        {['QUEUED', 'PROGRESSING'].includes(medium.state) ? (
          <div className="cell small-6 auto large-7 end flex-container align-middle text-stable-dark">
            <label className="o-label--custom o-label--stable-lighter text-bold mr-0-5 text-stable-dark">
              {S.dataTypes.transform.state[medium.state].toUpperCase()}
            </label>
            The linked video will show here when it has finished processing.
          </div>
        ) : (
          <EditorTimeSlider />
        )}
      </div>
    </div>
  )
}

export default EditorControlsRow
