import React from 'react'
import { getRotation } from '../../../Editor/helpers/controls'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'
import useCurrentUser from '../../../../hooks/useCurrentUser'
import useSelectedNode from '../../../FlowV2/hooks/useSelectedNode'
import fonts from '../../../Editor/helpers/fonts'
import { isBrightColor } from '../../../../utils/helpers/color'

const Mediapanel = ({ id, element, state }) => {
  const fontUrl = fonts.latin

  const [, currentClient] = useCurrentUser()
  const { data: scene } = useSelectedNode()

  if (
    !scene ||
    !element ||
    !currentClient ||
    element.anchorX === null ||
    element.anchorY === null
  )
    return null

  const circleSize = 0.43

  const rotation = getRotation({
    x: element.anchorX,
    y: element.anchorY,
  })

  let labelWidth = 1.8
  if (element.labelSize === 'WIDE') labelWidth *= 2

  const collisionWidth = labelWidth + 0.5
  const textWidth = 0.6

  return (
    <>
      <a-entity
        rotation={`${rotation} 0`}
        hideable-element={`visible: ${state.showElements}`}>
        <a-collision-box
          id={id}
          width={collisionWidth}
          scroll-header-offset={260}>
          <a-entity>
            {element.showLabel !== false && (
              <a-entity position={`0 ${circleSize + 0.1} 0`}>
                <TextBubble
                  textValue={element.label || 'Media panel'}
                  id={`H${id}`}
                  type="hotspot"
                  width={labelWidth}
                />
              </a-entity>
            )}
            {/*  Circle */}
            <a-circle
              radius={circleSize}
              material={`
              color: ${currentClient.primaryColor};
              shader: flat;
            `}
              position="0 0 0.01">
              <a-entity position={`0 ${textWidth / 4} 0`}>
                <a-text
                  id={`${id}-text`}
                  font={fontUrl}
                  value="+"
                  color={
                    isBrightColor(currentClient.primaryColor)
                      ? 'black'
                      : 'white'
                  }
                  anchor="center"
                  align="center"
                  material="shader: flat; visible: false; color: orange;"
                  geometry="primitive: plane; width: auto; height: auto;"
                  width={textWidth}
                  wrap-count={1}
                  shader="msdf"
                  negate="false"
                />
              </a-entity>
            </a-circle>
          </a-entity>
        </a-collision-box>
      </a-entity>
    </>
  )
}
export default Mediapanel
